export type AlignItems = 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'initial' | 'inherit';
export type JustifyContent = 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | 'initial' | 'inherit';

/**
 * @minLength 1
 */
type NotEmptyString = string;

// TODO possibly apply color validation (though some sites can use named colors which is difficult to validate without a list)
// /**
//  * @pattern ^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$
//  * @example '#FFFFFF'
//  */
// type HexColor = string;

// /**
//  * @pattern ^rgb\((\d{1,3}),\s?(\d{1,3}),\s?(\d{1,3})\)$
//  * @example 'rgb(255,255,255)'
//  */
// type RGBColor = string;

// /**
//  * @pattern ^rgba\((\d{1,3}),\s?(\d{1,3}),\s?(\d{1,3})(?:,\s?(\d(\.\d+)?|1(\.0+)?))?\)$
//  * @example 'rgba(255,255,255,0.5)'
//  */
// type RGBAColor = string;

// type Color = HexColor | RGBColor | RGBAColor | 'transparent';

type AssetsOrigin = 'assets' | 'unsplash' | 'other';

type SiteTypographyFontFamily = 'var(--font-primary)' | 'var(--font-secondary)'; // possibly too strict and string would be sufficient

/**
 * @pattern ^(?:[1-9][0-9]*|[1-9])px$
 * @example '12px'
 */
type SiteTypographyFontSize = string;

/**
 * @pattern ^(normal|italic|oblique(?: -?(?:[1-8]?[0-9]|90)deg)?)$
 * @example 'oblique -10deg'
 */
type SiteTypographyFontStyle = string;
type SiteTypographyFontWeight = 100
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900
  | 'normal'
  | 'bold';

/**
 * @pattern ^(0|[1-9]\d*|0?\.\d+|\d+\.\d+)(em|rem)?$|^[1-9]\d*px$|^normal$
 * @example '1.2'
 */
type SiteTypographyLineHeight = string;
type SiteTypographyTextDecoration = 'none' | 'underline' | 'underline';
type SiteTypographyTextTransform = 'capitalize' | 'uppercase' | 'lowercase' | 'none';

// allow positive and negative px (no decimal px), em, rem values, 0, and normal
/**
 * @pattern ^(-?\d+(\.\d+)?(em|rem)|-?\d+(px)|normal|(\.\d+)(em|rem)|0)$
 * @example '-10rem'
 */
type SiteTypographyLetterSpacingString = string;

type SiteTypographyLetterSpacing = SiteTypographyLetterSpacingString | 0;

/**
 * allows positive px (no decimal px), em, rem values, 0
 * @pattern ^(?:\d+(\.\d+)?(em|rem)|\d+px|0|(\.\d+)(em|rem))$
 * @example '12px'
 */
type SiteTypographyPadding = string;

/**
 * @minimum 0
 * @maximum 999
 * @example 10
 */
type SiteTypographyBorderRadius = number;

/**
 * allows empty strings, positive px (no decimal px), em, rem values, 0, and none
 * @pattern ^(?:\d+(\.\d+)?(em|rem)|\d+px|none|0|(\.\d+)(em|rem))?$
 * @example '10em'
 */
type SiteTypographyBorderNull = string;

/**
 * allow positive and negative px (no decimal px), em, rem values and 0
 * @pattern ^(-?\d+(\.\d+)?(em|rem)|-?\d+(px)|(\.\d+)(em|rem)|0)$
 * @example '-10rem'
 */
type SiteTypographyShadowUnit = string;

/**
 * @pattern ^(?:0|[1-9]\d*(?:\.\d+)?(ms|s)|0?\.\d+(ms|s))$|^initial$
 * @example '0.2s'
 */
type SiteTypographyTransitionDuration = string;

type EcommerceListAlignment = 'outlined' | 'left' | 'center';

export type SiteBackgroundType = 'path' | 'origin' | 'color' | 'gradient' | 'image' | 'video';

/**
 * @pattern ^(|pcol_.*)$
 */
type ProductCategoryId = string;

type MobileLinksAlignment = 'right' | 'left' | 'center';

export type AnimationType = 'global' | 'element';

type BlockSettingsAlignment = 'right' | 'left' | 'center';

/**
 * @pattern ^<svg
 */
type LogoSvg = string;

/**
 * @pattern ^https:\/\/.*
 */
type HttpsString = string;

/**
 * @pattern ^(0|(\d+px))(\s+(0|(\d+px))){0,3}$
 */
type PixelsMultiple = string;
/**
 * @pattern ^(\d+px)|0$
 */
type Pixels = string;
/**
 * @pattern ^(100|[1-9]?[0-9])%$
 */
type Percent = string;
/**
 * @minimum 0
 */
type PositiveNumber = number;
/**
 * @pattern ^\d+$
 */
type PositiveNumberString = string;

export type BackgroundAttachmentOptions = 'fixed' | 'unset';

export type BackgroundImagePositionOptions = 'top' | 'center' | 'bottom';

/**
 * @pattern ^\d+$
 */
type Digits = string;

type PasswordDesign = 'default' | 'calm' | 'popular';

/**
 * @pattern ^[a-zA-Z0-9-_]*$
 * @example 'some-slug'
 */
type PageSlug = string;

/**
 * @minLength 1
 * @pattern ^(?!null$).*$
 * @example 'someid'
 */
type KeyString = NotEmptyString;

/**
 * @pattern \.svg$
 */
type Svg = string;

/**
 * @pattern flag$
 * @example 'lt flag'
 */
type SiteLanguageFlagAlt = string;

/**
 * @pattern ^(\w|-){1,30}$
 * @example '123-456-7890'
 */
type GoogleAdsId = string;

export type GoogleAdsIds = GoogleAdsId[] | undefined;

/**
 * @pattern ^store_.*
 * @example 'store_12345678901234567890123456'
 */
type EcommerceStoreId = string;

type EcommerceProductId = string;

/**
 * @pattern ^data:image.*
 */
type ShapeMaskSource = string;

/**
 * @pattern ^https:\/\/maps\.google\.com
 * @example 'https://maps.google.com/maps?q=asd123&t=m&z=13&ie=UTF8&output=embed'
 */
type GoogleMapsSrc = string;

/**
 * @pattern ^variant_.*
 * @example 'variant_01JJ6FKW7X4TEQ4D04M6MV9QAA'
 */
type EcommerceProductVariantId = string;

/**
 * @pattern ^demo_|store_.*
 * @example 'demo_12345678901234567890123456'
 */
type DemoEcommerceStoreId = string;

/**
 * @pattern GTM-\w{1,30}
 * @example 'GTM-NSP53KRD'
 */
type GoogleTagManagerId = string;

/**
 * @pattern ^\w{3,30}$
 * @example '447350337515291'
 */
type FacebookPixelId = string;

/**
 * @pattern UA-\w*-\w*|G-\w*
 * @example 'G-9HH4V7RJRP'
 */
type GoogleAnalyticsId = string;

/**
 * @pattern ^\w{3,30}$
 * @example '5268261'
 */
type HotjarId = string;

/**
 * @pattern ^\+?\d+$
 * @example '37066666666'
 */
type WhatsAppNumber = string;

/**
 * @pattern ^pk_(live|test)_\w{3,99}$
 * @example 'pk_test_VOOyyYjgzqdm8I3SrGqmh3q2'
 */
type StripePublicApiKey = string;

export type AiWebsiteType = 'Online store' | 'Blog' | 'Business' | 'Portfolio' | 'Restaurant' | 'Weddings invitation' | 'Other' | 'Landing page';

/**
 * @minLength 3
 * @example "'Red Hat Text', sans-serif"
 */
type SiteTypographyFont = string;

/**
 * @minLength 32
 * @maxLength 32
 * @example A85EpLJVabc9b145z6Xnd9rOb4lY9abc
 */
type FormToken = string;

export type EcommerceGalleryPlacement = 'left' | 'bottom';

export enum SortingOptions {
	DEFAULT = 'default',
	PRICE_LTH = 'priceLTH',
	PRICE_HTL = 'priceHTL',
	MOST_RECENT = 'mostRecent',
}

export const LINK_PREFIXES = {
	HTTPS: 'https://',
	HTTP: 'http://',
	TEL: 'tel:',
	EMAIL: 'mailto:',
	BASE: '/',
} as const;

export enum LINK_TYPE {
	EXTERNAL = 'external',
	DOWNLOAD = 'download',
	ANCHORED_SECTION = 'anchored-section',
	EMAIL = 'email',
	PHONE = 'phone',
	PAGE = 'page',
}

export enum NAVIGATION_TYPE {
	LINK ='Link',
	PAGE = 'Page',
	FOLDER = 'Folder',
	HOMEPAGE = 'Homepage',
}

export interface SiteElementGridGalleryImage {
  alt: string;
  path: string;
  origin: AssetsOrigin;
}

export enum ImageHoverEffect {
	NO_EFFECT = 'no_effect',
	ZOOM = 'zoom',
	SWAP_IMAGE = 'swap_image'
}

export enum ImageRatioOption {
	CONTAIN = 'contain',
	COVER = 'cover',
	LANDSCAPE = 'landscape',
	PORTRAIT = 'portrait',
}

export type SiteElementType = 'GridGallery'
  | 'GridTextBox'
  | 'GridShape'
  | 'GridImage'
  | 'GridButton'
  | 'GridMap'
  | 'GridVideo'
  | 'GridInstagramFeed'
  | 'GridSocialIcons'
  | 'GridForm'
  | 'GridEmbed'
  | 'GridStripeButton'
  | 'GridEcommerceButton'
  | 'SearchBar';

export enum ELEMENT_TYPES {
	ELEMENT_TYPE_BUTTON = 'GridButton',
	ELEMENT_TYPE_STRIPE_BUTTON = 'GridStripeButton',
	ELEMENT_TYPE_ECOMMERCE_BUTTON = 'GridEcommerceButton',
	ELEMENT_TYPE_MAP = 'GridMap',
	ELEMENT_TYPE_VIDEO = 'GridVideo',
	ELEMENT_TYPE_IMAGE = 'GridImage',
	ELEMENT_TYPE_TEXT_BOX = 'GridTextBox',
	ELEMENT_TYPE_FORM = 'GridForm',
	ELEMENT_TYPE_INSTAGRAM_FEED = 'GridInstagramFeed',
	ELEMENT_TYPE_SOCIAL_ICONS = 'GridSocialIcons',
	ELEMENT_TYPE_GALLERY = 'GridGallery',
	ELEMENT_TYPE_EMBED = 'GridEmbed',
	ELEMENT_TYPE_SHAPE = 'GridShape',
	ELEMENT_TYPE_SEARCH_BAR = 'SearchBar',
}

export enum BLOCK_TYPES {
	BLOCK_NAVIGATION = 'BlockNavigation',
	BLOCK_BLOG_LIST = 'BlockBlogList',
	BLOCK_BLOG_HEADER = 'BlockBlogHeader',
	BLOCK_ECOMMERCE_PRODUCT = 'BlockEcommerceProduct',
	BLOCK_ECOMMERCE_PRODUCT_LIST = 'BlockEcommerceProductList',
	BLOCK_LAYOUT = 'BlockLayout',
	BLOCK_IMAGES_SLIDESHOW = 'BlockImageSlideshow',
	BLOCK_STICKY_BAR = 'BlockStickyBar',
}

export enum EcommerceProductListScrollBehaviour {
	PAGINATION = 'pagination',
	SCROLL = 'scroll',
}

export enum AnimationKeys {
    None = 'none',
    Fade = 'fade',
    Slide = 'slide',
    Scale = 'scale'
}

export enum ElementShapes {
	RECTANGLE = 'rectangle',
	ELLIPSE = 'ellipse',
	TRIANGLE = 'triangle',
	RHOMBUS = 'rhombus',
	HEXAGON = 'hexagon',
	OCTAGON = 'octagon',
	STAR = 'star',
	DODECAGRAM = 'dodecagram',
	HOR_LINE = 'horizontal-line',
	BOLDEN_HOR_LINE = 'bolded-horizontal-line',
	DOTTED_HOR_LINE = 'dotted-horizontal-line',
	DASHED_HOR_LINE = 'dashed-horizontal-line',
	VER_LINE = 'vertical-line',
	BOLDED_VER_LINE = 'bolded-vertical-line',
	DOTTED_VER_LINE = 'dotted-vertical-line',
	DASHED_VER_LINE = 'dashed-vertical-line',
}

/**
 * @strict
 */
interface BlockAnimation {
	name: AnimationKeys;
	type: AnimationType;
}

export interface SiteElementGridSocialIconLink {
  svg: LogoSvg;
  icon: string;
  link?: string;
  url?: string;
}

/**
 * @strict
 */
export interface BaseElementPosition {
	top: PositiveNumber;
	left: PositiveNumber;
	width: PositiveNumber;
	height: PositiveNumber;
	isHidden?: boolean;
}

/**
 * @strict
 */
export interface FormElementPosition extends BaseElementPosition {
    inputTextSize?: PositiveNumber;
    labelTextSize?: PositiveNumber;
    submitButtonFontSize?: PositiveNumber;
    formElementsVerticalSpacing?: PositiveNumber;
}

/**
 * @strict
 */
export interface GalleryElementPosition extends BaseElementPosition {
	columnGap: PositiveNumber;
	columnCount: PositiveNumber;
}

/**
 * @strict
 */
export interface ButtonElementPosition extends BaseElementPosition {
	fontSize?: PositiveNumber;
}

/**
 * @strict
 */
export interface ImageElementPosition extends BaseElementPosition {
	borderRadius?: string;
}

/**
 * @strict
 */
export interface ButtonElementSettings {
	type: 'primary' | 'secondary',
	styles: {
		align?: 'center' | 'flex-start';
		justify?: 'center' | 'flex-start';
		'm-element-margin'?: PixelsMultiple;
		'grid-button-primary-color'?: string;
		'grid-button-primary-color-hover'?: string;
		'grid-button-primary-color-active'?: string;
		'grid-button-primary-background-color'?: string;
		'grid-button-primary-background-color-hover'?: string;
		'grid-button-primary-background-color-active'?: string;
	}
}

/**
 * @strict
 */
export interface EcommerceButtonElementSettings extends ButtonElementSettings {
	productVariantId: -1 | EcommerceProductVariantId;
	productId?: EcommerceProductId
	href?: string;
}

/**
 * @strict
 */
export interface SiteElementGridButton {
	href?: string; // probably should not be optional, but many sites break if so
	type: ELEMENT_TYPES.ELEMENT_TYPE_BUTTON;
	content: string;
	mobile: ButtonElementPosition;
	desktop: ButtonElementPosition
	linkType: LINK_TYPE;
	settings: ButtonElementSettings;
	fontColor: string;
	borderColor: string;
	fontColorHover: string;
	backgroundColor: string;
	borderColorHover: string;
	backgroundColorHover: string;
	rel?: 'nofollow' | '';
	target?: '_blank' | '_self';
	animation?: BlockAnimation;
	linkedPageId?: string;
	borderWidth?: PositiveNumber;
	borderRadius?: PositiveNumber;
	borderWidthHover?: PositiveNumber;
	fontFamily?: string;
	fontWeight?: SiteTypographyFontWeight;
	initialElementId?: string;
	aiData?: {
		type?: string;
		[k: string]: unknown;
	};
}

// GridStripeButton is possibly deprecated
export interface SiteElementGridStripeButton {
  type: ELEMENT_TYPES.ELEMENT_TYPE_STRIPE_BUTTON;
  animation?: BlockAnimation;
  content: string;
  /**
   * @strict
   */
  settings: {
	styles: object;
	target?: string
	href?: string;
	priceId: string;
	paymentType: string;
  }
  aiData?: {
	type?: string;
	[k: string]: unknown;
  };
}

/**
 * @strict
 */
export interface SiteElementGridEcommerceButton {
	type: ELEMENT_TYPES.ELEMENT_TYPE_ECOMMERCE_BUTTON
	content: string;
	mobile: ButtonElementPosition;
	desktop: ButtonElementPosition;
	settings: EcommerceButtonElementSettings;
	animation?: BlockAnimation;
	borderColor: string;
	backgroundColor: string;
	borderColorHover: string;
	backgroundColorHover: string;
	fontColor: string;
	fontColorHover: string;
	borderWidth?: PositiveNumber;
	borderRadius?: PositiveNumber;
	borderWidthHover?: PositiveNumber;
	fontFamily?: string;
	fontWeight?: SiteTypographyFontWeight;
	initialElementId?: string;
	aiData?: {
		type?: string;
		[k: string]: unknown;
	};
}

/**
 * @strict
 */
export interface SiteElementGridMap {
  type: ELEMENT_TYPES.ELEMENT_TYPE_MAP;
  mobile: BaseElementPosition;
  desktop: BaseElementPosition;
  /**
   * @strict
   */
  settings: {
	src: GoogleMapsSrc;
	/**
	 * @strict
	 */
	styles: {
		align: 'center' | 'flex-start';
		justify: 'center' | 'flex-start';
        'grid-item-padding'?: PixelsMultiple;
		'm-element-margin'?: PixelsMultiple;
		'm-width'?: string;
	},
	'm-element-margin'?: PixelsMultiple;
  };
  initialElementId?: string;
  animation?: BlockAnimation;
  aiData?: {
	type?: string;
	[k: string]: unknown;
  };
}

/**
 * @strict
 */
export interface SiteElementGridVideo {
  type: ELEMENT_TYPES.ELEMENT_TYPE_VIDEO,
  mobile: BaseElementPosition;
  desktop: BaseElementPosition;
  /**
   * @strict
   */
  settings: {
	src: HttpsString;
	styles: {
		align?: 'center' | 'flex-start';
		justify?: 'center' | 'flex-start';
		'm-element-margin'?: PixelsMultiple;
	},
    provider?: 'youtube' | 'vimeo';
    jpg?: HttpsString;
    webp?: HttpsString;
	initialSrc?: HttpsString,
  }
  animation?: BlockAnimation;
  initialElementId?: string;
  aiData?: {
	type?: string;
	[k: string]: unknown;
  };
}

/**
 * @strict
 */
export interface SiteElementGridTextBox {
  type: ELEMENT_TYPES.ELEMENT_TYPE_TEXT_BOX;
  content: string;
  mobile: BaseElementPosition;
  desktop: BaseElementPosition;
  settings: {
	styles: {
		text?: 'left' | 'center' | 'justify' | 'right';
		align?: 'flex-start';
		justify?: 'flex-start' | 'flex-end' | 'center';
		'm-element-margin'?: PixelsMultiple;
		'm-text'?: 'left' | 'center' | 'justify' | 'right';
		'm-width'?: string;
		'm-align-self'?: string;
	}
  };
  animation?: BlockAnimation;
  backgroundColor?: string;
  initialElementId?: string;
  aiData?: {
	type?: string;
	[k: string]: unknown;
  };
}

/**
 * @strict
 */
export interface SiteElementGridShape {
  type: ELEMENT_TYPES.ELEMENT_TYPE_SHAPE;
  svg: LogoSvg;
  color: string;
  shape: ElementShapes;
  mobile: BaseElementPosition;
  desktop: BaseElementPosition;
  animation?: BlockAnimation;
  initialElementId?: string;
  settings?: {
	styles?: object | any[];
  }
  aiData?: {
	type?: string;
	[k: string]: unknown;
  };
}

/**
 * @strict
 */
export interface SiteElementGridImage {
	type: ELEMENT_TYPES.ELEMENT_TYPE_IMAGE;
	mobile: ImageElementPosition;
	desktop: ImageElementPosition;
	rel?: 'nofollow' | '';
	/**
	 * @strict
	 */
	settings: {
		alt: string;
		path: NotEmptyString;
		origin: AssetsOrigin;
		styles: {
			align?: 'center';
			justify?: 'center';
			'm-element-margin'?: PixelsMultiple;
			'm-align-self'?: string;
			'm-width'?: string;
		},
		clickAction?: 'none' | 'link' | 'lightbox';
		target?: '_blank' | '_self';
	}
	animation?: BlockAnimation;
	target?: '_blank' | '_self';
	href?: string;
	linkType?: LINK_TYPE;
	linkedPageId?: string;
    overlayOpacity?: PositiveNumber;
    shapeMaskSource?: ShapeMaskSource;
    fullResolutionWidth?: PositiveNumber;
    fullResolutionHeight?: PositiveNumber;
	initialElementId?: string;
	aiData?: {
		type?: string;
		[k: string]: unknown;
	};
}

/**
 * @strict
 */
export interface SiteElementGridInstagramFeed {
	type: ELEMENT_TYPES.ELEMENT_TYPE_INSTAGRAM_FEED;
	mobile: BaseElementPosition;
	desktop: BaseElementPosition;
	/**
	 * @strict
	 */
	settings: {
		styles: {
			align?: 'center';
			justify?: 'flex-start';
			'item-gap'?: Pixels;
			'm-item-gap'?: Pixels;
			'items-per-row'?: PositiveNumber;
			'm-items-per-row'?: PositiveNumber;
			'm-element-margin'?: PixelsMultiple;
		};
		'item-count': PositiveNumber;
		apiVersion?: string;
		username?: string;
	};
	username?: string;
	animation?: BlockAnimation;
	initialElementId?: string;
	aiData?: {
		type?: string;
		[k: string]: unknown;
	};
}

/**
 * @strict
 */
export interface SiteElementGridSocialIcons {
	type: ELEMENT_TYPES.ELEMENT_TYPE_SOCIAL_ICONS;
	links: SiteElementGridSocialIconLink[];
	mobile: BaseElementPosition;
	desktop: BaseElementPosition;
	/**
 	 * @strict
 	 */
	settings: {
		useBrandColors: boolean;
		styles: {
			'icon-size'?: Pixels;
			'icon-spacing'?: 'space-around' | 'space-between';
			'icon-direction'?: 'row' | 'column'
			'm-element-margin'?: PixelsMultiple;
			'space-between-icons'?: Pixels;
			'icon-color'?: string;
			'icon-color-hover'?: string;
			align?: '';
			justify?: 'center';
	};
  },
  animation?: BlockAnimation;
  initialElementId?: string;
  aiData?: {
	type?: string;
	[k: string]: unknown;
  };
}

/**
 * @strict
 */
export interface SiteElementGridGallery {
	type: ELEMENT_TYPES.ELEMENT_TYPE_GALLERY;
	images: SiteElementGridGalleryImage[];
	mobile: GalleryElementPosition;
	desktop: GalleryElementPosition;
	/**
 	 * @strict
 	 */
	settings: {
		layout: 'grid' | 'masonry';
		styles: {
			'm-element-margin'?: PixelsMultiple;
		},
		imageClickAction: 'lightbox' | 'none';
	};
	animation?: BlockAnimation;
	initialElementId?: string;
	aiData?: {
		type?: string;
		[k: string]: unknown;
	};
}

/**
 * @strict
 */
export interface SiteElementGridEmbed {
	type: ELEMENT_TYPES.ELEMENT_TYPE_EMBED;
	mobile: BaseElementPosition;
	desktop: BaseElementPosition;
	content: string;
	/**
  	 * @strict
 	 */
	settings: {
		styles: {
		};
	};
	aiData?: {
		type?: string;
		[k: string]: unknown;
	};
}

/**
 * @strict
 */
export interface SiteElementSearchBar {
  type: ELEMENT_TYPES.ELEMENT_TYPE_SEARCH_BAR;
  mobile: BaseElementPosition;
  desktop: BaseElementPosition;
  fillColor: string;
  fontFamily: string;
  borderColor: string;
  borderRadius: PositiveNumber;
  noResultsText: string;
  fillColorHover: string;
  placeholderText: string;
  borderColorHover: string;
  textAndIconColor: string;
  resultItemHoverColor: string;
  textAndIconColorHover: string;
  animation?: BlockAnimation;
  initialElementId?: string;
  aiData?: {
	type?: string;
	[k: string]: unknown;
  };
}

/**
 * @strict
 */
export interface SiteElementGridForm {
	type: ELEMENT_TYPES.ELEMENT_TYPE_FORM;
	formId: string;
	mobile: FormElementPosition;
	desktop: FormElementPosition;
	/**
  	 * @strict
 	 */
	settings: {
		type?: 'singleField';
		theme: 'light' | 'dark';
		schema:{
				id: string;
				svg: string;
				name: string;
				type: string;
				inputLabel: string;
				validation: string[][];
				'validation-messages': Record<string, string>
				fieldType?: string;
				validationType?: string;
				placeholder?: string;
				tag?: 'checkbox' | 'radio' | 'textarea' | 'input';
				options?: { id: string, value: string}[]
			}[],
		styles: {
			justify?: 'center';
			formSpacing?: PixelsMultiple;
			'm-element-margin'?: PixelsMultiple;
			align?: 'space-between';
			text?: 'left';
			'm-width'?: string;
			'm-align-self'?: string;
		},
		successMessage: string;
		submitAction?: {
			id: 'linkToPage' | 'showMessage';
			title: string;
		},
		submitRedirectPage?: {
			title: string;
			pageId: string;
		}
	},
	inputFillColor: string;
	inputTextColor: string;
	labelTextColor: string;
	innerBackground: {
		color: string;
		current: '';
		image?: '';
	};
	inputBorderColor: string;
	submitButtonData: {
		type: ELEMENT_TYPES.ELEMENT_TYPE_BUTTON;
		content: string;
		settings: {
			type: 'primary' | 'secondary';
			styles: {
				position: '8/8/9/10';
				align: 'start' | 'center' | 'end';
				'm-align'?: 'start' | 'center' | 'end';
				justify?: 'center';
			},
			isFormButton: boolean;
		}
	},
	inputFillColorHover: string;
	submitButtonFontColor: string;
	submitButtonBorderColor: string;
	submitButtonFontColorHover: string;
	submitButtonBackgroundColor: string;
	submitButtonBorderColorHover: string;
	submitButtonBackgroundColorHover: string;
	inputBorderWidth?: PositiveNumber;
	formBorderColor?: string;
	submitButtonBorderWidth?: PositiveNumber;
	submitButtonBorderRadius?: PositiveNumber;
	submitButtonBorderWidthHover?: PositiveNumber;
	formFontFamily?: string;
	formFontWeight?: SiteTypographyFontWeight;
	fontFileType?: 'woff2' | 'woff' | 'ttf' | 'otf';
	animation?: BlockAnimation;
	initialElementId?: string;
	formPadding?: PositiveNumber;
	formBorderWidth?: PositiveNumber;
	formBorderRadius?: PositiveNumber;
	inputBorderRadius?: PositiveNumber;
	formBackgroundColor?: string;
	inputTextColorHover?: string;
	inputBorderColorHover?: string;
	submitButtonFontFamily?: string;
	submitButtonFontWeight?: SiteTypographyFontWeight;
	aiData?: {
		type?: string;
		[k: string]: unknown;
	};
}

/**
 * @strict
 */
export interface SiteTypographyStyles {
  'font-family': SiteTypographyFontFamily;
  'font-size': SiteTypographyFontSize;
  'm-font-size': SiteTypographyFontSize;
  'font-style': SiteTypographyFontStyle;
  'font-weight': SiteTypographyFontWeight;
  'line-height': SiteTypographyLineHeight;
  'text-decoration': SiteTypographyTextDecoration;
  'text-transform': SiteTypographyTextTransform;
  'letter-spacing': SiteTypographyLetterSpacing;
}

/**
 * @strict
 */
interface SiteNavLinkTypographyStyles extends SiteTypographyStyles {
  color?: string;
  'color-hover'?: string;
}

/**
 * @strict
 */
export interface SiteGridButtonStyle extends SiteTypographyStyles {
  'padding-y': SiteTypographyPadding;
  'm-padding-y': SiteTypographyPadding;
  'padding-x': SiteTypographyPadding;
  'm-padding-x': SiteTypographyPadding;
  'border-radius': SiteTypographyBorderRadius;
  'background-color-null': string; // used as a fallback e.g. in product page button
  'background-color-null-hover': string; // used as a fallback e.g. in product page button
  'background-color'?: string; // used as a fallback
  'background-color-hover'?: string; // used as a fallback
  'border-null': SiteTypographyBorderNull; // used e.g. in e-commerce product list
  'border-null-hover': SiteTypographyBorderNull; // used e.g. in e-commerce product list
  'box-shadow-null': string; // takes full box-shadow css value
  'box-shadow-null-hover': string; // takes full box-shadow css value
  'box-shadow-color': string;
  'box-shadow-color-hover': string;
  'box-shadow-x': SiteTypographyShadowUnit;
  'box-shadow-x-hover': SiteTypographyShadowUnit;
  'box-shadow-blur': SiteTypographyShadowUnit;
  'box-shadow-blur-hover': SiteTypographyShadowUnit;
  'box-shadow-spread': SiteTypographyShadowUnit;
  'box-shadow-spread-hover': SiteTypographyShadowUnit;
  'box-shadow-y': SiteTypographyShadowUnit;
  'box-shadow-y-hover': SiteTypographyShadowUnit;
  'transition-duration': SiteTypographyTransitionDuration;
  'transition-timing-function': 'ease' | 'ease-in' | 'ease-out' | 'ease-in-out' | 'linear';
  'font-color'?: string;
  'color-hover'?: string;
}

export interface SiteBackgroundGradient {
  /**
  * @minimum 0
  * @maximum 360
  */
  angle: number;
  isAnimated: boolean;
  colors: { value: string }[];
}

export type SiteEcommerceShoppingCart = {
  translations: Record<string, string>;
  lang?: string;
  [k: string]: unknown;
}

export type SiteEcommerceSeoChanges = {
  id: string;
  seo_settings: {
    title?: string;
    noindex?: boolean;
    keywords?: string[];
    description?: string;
    focusKeyword?: string;
    ogImageOrigin?: string;
    ogImagePath?: string;
    ogImageAlt?: string;
    slug?: string;
  }
}

export type SiteBlockSettings = {
  styles?: {
    [k: string]: unknown;
  };
  categories?: string[];
  logoSvg?: string;
}

export type SiteEcommerceSortingValue = '' | 'order=ASC&sort_by=price' | 'order=DESC&sort_by=price' | 'order=DESC&sort_by=created_at' | 'order=ASC&sort_by=collection_order';

export type BlockHeaderLayoutMobile1 = 'mobile-1';
export type BlockHeaderLayoutMobile2 = 'mobile-2';
export type BlockHeaderLayoutMobile3 = 'mobile-3';
export type BlockHeaderLayoutDesktop1 = 'desktop-1';
export type BlockHeaderLayoutDesktop2 = 'desktop-2';
export type BlockHeaderLayoutDesktop3 = 'desktop-3';
export type BlockHeaderLayoutDesktop4 = 'desktop-4';
export type BlockHeaderLayoutDesktop5 = 'desktop-5';

export type BlockHeaderDesktopLayouts = BlockHeaderLayoutDesktop1
| BlockHeaderLayoutDesktop2
| BlockHeaderLayoutDesktop3
| BlockHeaderLayoutDesktop4
| BlockHeaderLayoutDesktop5
export type BlockHeaderMobileLayouts = BlockHeaderLayoutMobile1
| BlockHeaderLayoutMobile2
| BlockHeaderLayoutMobile3
export type BlockHeaderLayouts = BlockHeaderDesktopLayouts | BlockHeaderMobileLayouts;

/**
 * @strict
 */
interface BlockBackground {
	origin?: AssetsOrigin;
	path?: string;
	image?: HttpsString;
	color?: string;
	current: 'color' | 'image' | 'video';

	video?: {
		videoSrc: HttpsString;
		videoThumbnailSrc: HttpsString;
	},
	/**
	 * pattern ^(\d(\.\d{1,2})?|100(\.00)?)$
	 */
	'overlay-opacity'?: string;
}

/**
 * @strict
 */
interface StickyBlockBackground {
	origin?: AssetsOrigin;
	path?: string;
	image?: HttpsString;
	color?: string;
	current: 'color' | 'image';
}

/**
 * @strict
 */
interface EcommerceProductListBlockBackground {
	origin?: string;
	path?: string;
	image?: HttpsString;
	color?: string;
	current: 'color' | 'image' | 'video';
	video?: {
		videoSrc: HttpsString;
		videoThumbnailSrc: HttpsString;
	},
	/**
	 * pattern ^(\d(\.\d{1,2})?|100(\.00)?)$
	 */
	'overlay-opacity'?: string;
}

/**
 * @strict
 */
interface LayoutBlockBackground {
	alt?: string;
	origin?: AssetsOrigin | null;
	path?: string | null;
	image?: HttpsString | null;
	color?: string | null;
	current: 'color' | 'image' | 'video';
	video?: {
		videoSrc: HttpsString;
		videoThumbnailSrc: HttpsString;
	},
	/**
	 * pattern ^(\d(\.\d{1,2})?|100(\.00)?)$
	 */
	'overlay-opacity'?: string;
	/**
	 * @strict
	 */
	gradient?: {
		angle: PositiveNumber;
		colors: { value: string; }[];
		isAnimated: boolean
	};
}

/**
 * @strict
 */
interface NavigationBlockBackground {
	color: string;
	current: 'color';
	isTransparent: boolean;
	temporaryBackground?: object;
}

/**
 * @strict
 */
export interface NavigationBlockPositions {
	height?: PositiveNumber;
	logoHeight?: PositiveNumber;
}

/**
 * @strict
 */
export interface LayoutBlockPositions {
	isHidden?: boolean;
	minHeight?: PositiveNumber;
}

/**
 * @strict
 */
interface EcommerceListPositions {
	rowGap?: PositiveNumber;
	columnGap?: PositiveNumber;
	columnCount?: PositiveNumber;
}

/**
 * @strict
 */
interface SortingOption {
	id: SortingOptions;
	value: SiteEcommerceSortingValue;
	isEnabled: boolean;
	label?: string;
}

/**
 * @strict
 */
interface RibbonStyle {
	'ribbon-color': string;
	'ribbon-background-color': string;
}

/**
 * @strict
 */
interface SlideshowSlide {
	alt: string;
	origin: AssetsOrigin;
	path: NotEmptyString;
	fullResolutionWidth?: PositiveNumber,
	fullResolutionHeight?: PositiveNumber
	url?: HttpsString;
}

/**
 * @strict
 */
interface EcommerceTextColorVars {
	'h1-color': string;
	'h2-color': string;
	'h3-color': string;
	'h4-color': string;
	'h5-color': string;
	'h6-color': string;
	'body-color': string;
	'body-large-color': string;
}

/**
 * @strict
 */
interface EcommerceButtonStyle {
	'grid-button-primary-color'?: string;
	'grid-button-secondary-color'?: string;
	'grid-button-primary-font-size'?: Pixels;
	'grid-button-primary-padding-x'?: Pixels;
	'grid-button-primary-padding-y'?: Pixels;
	'grid-button-primary-color-hover'?: string;
	'grid-button-primary-m-font-size'?: Pixels;
	'grid-button-primary-m-padding-x'?: Pixels;
	'grid-button-primary-m-padding-y'?: Pixels;
	'grid-button-primary-border-color': string;
	'grid-button-primary-color-active'?: string;
	'grid-button-secondary-color-hover'?: string;
	'grid-button-secondary-border-color'?: string;
	'grid-button-primary-background-color'?: string;
	'grid-button-primary-border-color-hover'?: string;
	'grid-button-secondary-background-color'?: string;
	'grid-button-primary-border-color-active'?: string;
	'grid-button-secondary-border-color-hover': string;
	'grid-button-secondary-border-color-active': string;
	'grid-button-primary-background-color-hover': string;
	'grid-button-primary-background-color-active'?: string;
	'grid-button-secondary-background-color-hover'?: string;
	'grid-button-secondary-background-color-active'?: string;
}

/**
 * @strict
 */
interface EcommerceButtonProductStyle extends EcommerceButtonStyle {
	'grid-button-secondary-font-size'?: Pixels;
	'grid-button-secondary-padding-x'?: Pixels;
	'grid-button-secondary-padding-y'?: Pixels;
	'grid-button-secondary-m-font-size'?: Pixels;
	'grid-button-secondary-m-padding-x'?: Pixels;
	'grid-button-secondary-color-active'?: string;
}

/**
 * @strict
 */
export interface SiteBlockNavigation {
	type: BLOCK_TYPES.BLOCK_NAVIGATION;
	mobile: NavigationBlockPositions;
	desktop: NavigationBlockPositions;
	/**
	 * @strict
	 */
	settings: {
		styles: {
			width?: Pixels;
			padding?: PixelsMultiple;
			'logo-width'?: string; // should use Pixels but now we save subpixel values as well
			cartIconSize?: Pixels;
			'link-spacing'?: Pixels;
			'm-logo-width'?: string; // should use Pixels but now we save subpixel values as well
			'm-link-spacing'?: Pixels;
			'element-spacing'?: Pixels;
			contrastBackgroundColor?: string;
			'm-padding'?: PixelsMultiple;
		};
		cartText: string;
		isSticky: boolean;
		showLogo: boolean;
		headerLayout: BlockHeaderDesktopLayouts;
		isCartVisible: boolean;
		logoImagePath: string;
		mHeaderLayout: BlockHeaderMobileLayouts;
		logoImageOrigin: AssetsOrigin;
		mobileLinksAlignment: MobileLinksAlignment;
		logoSvg?: LogoSvg;
		isLanguageSwitcherHidden?: boolean
	};
	zindexes: string[];
	background: NavigationBlockBackground
	components: string[];
	navLinkTextColor: string;
	navLinkTextColorHover: string;
	fontFamily?: string;
	fontWeight?: SiteTypographyFontWeight;
	logoAspectRatio?: number;
	aiData?: {
		type?: string;
		[k: string]: unknown;
	};
}

/**
 * @strict
 */
export interface SiteBlockBlogHeader {
	type: BLOCK_TYPES.BLOCK_BLOG_HEADER;
	/**
	 * @strict
	 */
	settings: {
		styles: {
			align: 'flex-start',
			'text-align'?: BlockSettingsAlignment;
			'block-padding': PixelsMultiple;
			'content-width': Pixels;
			'm-block-padding': PixelsMultiple;
            'blog-post-header-text-color'?: string;
		},
		/**
 		 * @strict
 		 */
		shownItems: {
			date: boolean;
			avatar: boolean;
			categories: boolean;
			description: boolean;
			minutesToRead: boolean;
			authorFullName: boolean;
		},
		blogTitleFontSize?: PositiveNumber
	},
	animation: BlockAnimation;
	background: BlockBackground;
	components?: string[];
	zindexes?: string[];
	attachment?: BackgroundAttachmentOptions;
	aiData?: {
		type?: string;
		[k: string]: unknown;
	};
	initialBlockId?: string;
}

/**
 * @strict
 */
export interface SiteBlockBlogList {
	type: BLOCK_TYPES.BLOCK_BLOG_LIST;
	settings: {
		styles: {
			cols: PositiveNumber;
			rows: PositiveNumber;
			'row-gap': Pixels;
			'row-size': Pixels;
			'block-padding': Pixels;
			'content-width': Pixels;
			'grid-gap-size': Pixels;
			'm-grid-gap-size': Pixels;
			'cover-object-fit': 'contain';
            'blog-post-header-text-color'?: string;
		},
		fullWidth: boolean;
		categories: string[],
		/**
		 * @strict
		 */
		shownItems: {
			date: boolean;
			title: boolean;
			avatar: boolean;
			categories: boolean;
			coverImage: boolean;
			description: boolean;
			minutesToRead: boolean;
			authorFullName: boolean;
		},
		postsPerPage: PositiveNumber,
		showAllPosts: boolean;
		mockCategories: boolean;
		postColumnCount: PositiveNumber,
		showWithoutCategories: boolean;
		blogTitleFontSize?: PositiveNumber
	},
	zindexes?: string[];
	animation: BlockAnimation;
	background: BlockBackground;
	components: string[];
	attachment?: BackgroundAttachmentOptions;
	initialBlockId?: string;
	aiData?: {
		type?: string;
		[k: string]: unknown;
	};
}

/**
 * @strict
 */
export interface SiteBlockEcommerceProductList {
	type: BLOCK_TYPES.BLOCK_ECOMMERCE_PRODUCT_LIST;
	desktop?: EcommerceListPositions;
	mobile?: EcommerceListPositions;
	/**
 	 * @strict
	 */
	settings: {
		styles: {
			'block-padding'?: PixelsMultiple
			'content-width'?: Pixels;
			'm-block-padding'?: PixelsMultiple;
			textAlign?: EcommerceListAlignment;
            imageBorderRadius?: Percent;
			imageHoverEffect?: ImageHoverEffect;
		}
		imageHoverEffect?: ImageHoverEffect;
	},
	zindexes?: string[];
	animation?: BlockAnimation;
	background: EcommerceProductListBlockBackground;
	components?: string[];
	buttonStyle: EcommerceButtonStyle;
	ribbonStyle: RibbonStyle;
	textColorVars: EcommerceTextColorVars;
	productsPerPage: PositiveNumber;
	isButtonEnabled?: boolean;
	buttonBorderWidth?: PositiveNumber;
	productCategoryId?: ProductCategoryId;
	navigationArrowsColor?: string;
	shouldSnapToGuides?: boolean;
	initialBlockId?: string;
	isCategoryListEnabled?: boolean;
	attachment?: BackgroundAttachmentOptions;
	scrollBehaviour?: EcommerceProductListScrollBehaviour;
	isTotalProductCountShown?: boolean;
	isButtonFullWidth?: boolean;
	isFullWidth?: boolean;
	imageRatio?: ImageRatioOption;
	buttonText?: string;
	buttonDisplay?: string;
	productIds?: Array<string | null>;
	/**
	 * @strict
	 */
	productSorting?: {
		enabled: boolean;
		textColor: string;
		sortingOptions: SortingOption[]
	},
	aiData?: {
		type?: string;
		[k: string]: unknown;
	};
}

export interface SiteBlockEcommerceProduct {
	type: BLOCK_TYPES.BLOCK_ECOMMERCE_PRODUCT;
	/**
	 * @strict
	 */
	settings: {
		styles: {
			textAlign?: BlockSettingsAlignment;
			'block-padding': PixelsMultiple
			'content-width': Pixels;
			'm-block-padding': PixelsMultiple;
		}
	},
	product: {
		id: -1 | string;
	},
	textColorVars: EcommerceTextColorVars;
	galleryPlacement?: EcommerceGalleryPlacement;
	navigationArrowsColor: string;
	buttonStyle: EcommerceButtonProductStyle;
	animation?: BlockAnimation;
	navigationThumbnailArrowsColor?: string;
	background: BlockBackground;
	isQuantityPickerEnabled?: boolean;
	imageBorderRadius?: Percent;
	imageRatio?: ImageRatioOption;
	zindexes?: string[],
	components?: string[],
	buttonBorderWidth?: PositiveNumber;
	isExpressCheckoutEnabled?: boolean;
	aiData?: {
		type?: string;
		[k: string]: unknown;
	};
}

/**
 * @strict
 */
export interface SiteBlockLayout {
	type: BLOCK_TYPES.BLOCK_LAYOUT;
	htmlId?: string;
	slot?: 'footer';
	mobile?: LayoutBlockPositions;
	desktop?: LayoutBlockPositions;
	backgroundImagePosition?: BackgroundImagePositionOptions;
	/**
	 * @strict
	 */
	settings: {
		styles: {
			'block-padding'?: PixelsMultiple;
			'm-block-padding'?: PixelsMultiple;
			cols?: PositiveNumberString | PositiveNumber,
			rows?: PositiveNumberString | PositiveNumber,
			'm-rows'?: PositiveNumberString | PositiveNumber;
			'row-gap'?: Pixels;
			'row-size'?: Pixels;
			'column-gap'?: Pixels;
			'width'?: Pixels;
			'col-gap'?: Pixels;
			'grid-gap-history'?: PixelsMultiple
			oldContentWidth?: Pixels;
			'half-column-count'?: string;
		}
	},
	zindexes: string[];
	attachment?: BackgroundAttachmentOptions;
	background: LayoutBlockBackground;
	components: string[];
	initialBlockId?: string;
	shouldSnapToGuides?: boolean;
	snapRowGap?: PositiveNumber;
	snapRowHeight?: PositiveNumber;
	shouldKeepGridVisible?: boolean;
	shouldSnapToElements?: boolean;
	isMobileAutoPositioningEnabled?: boolean;
	aiData?: {
		type?: string;
		[k: string]: unknown;
	};
}

/**
 * @strict
 */
export interface SiteBlockImageSlideshow {
	type: BLOCK_TYPES.BLOCK_IMAGES_SLIDESHOW;
	mobile?: LayoutBlockPositions;
	desktop?: LayoutBlockPositions;
	slides: SlideshowSlide[];
	/**
	 * @strict
	 */
	settings: {
		styles: {
			navigationDotsColor: string;
			navigationArrowsColor: string;
		}
	},
	zindexes: string[];
	components: string[];
	isLoopEnabled: boolean;
	initialBlockId?: string
	/**
	 * @minimum 1
	 * @maximum 10
	 */
	autoplayInterval: number,
	isAutoplayEnabled: boolean;
	isNavigationDotsVisible: boolean;
	isNavigationArrowsVisible: boolean;
	aiData?: {
		type?: string;
		[k: string]: unknown;
	};
}

/**
 * @strict
 */
export interface SiteBlockStickyBar {
	type: BLOCK_TYPES.BLOCK_STICKY_BAR;
	mobile?: LayoutBlockPositions;
	desktop?: LayoutBlockPositions;
	backgroundImagePosition?: BackgroundImagePositionOptions;
	items: {
		type: 'text',
		content: string,
	}[],
	/**
	 * @strict
	 */
	settings: {
		styles: {
			'block-padding': PixelsMultiple;
			'm-block-padding': PixelsMultiple;
		}
	},
	zindexes: string[];
	components: string[];
	animation: BlockAnimation;
	background: StickyBlockBackground;
	aiData?: {
		type?: string;
		[k: string]: unknown;
	};
}

/**
 * @discriminator type
 */
export type SiteBlock = SiteBlockNavigation
| SiteBlockBlogList
| SiteBlockBlogHeader
| SiteBlockEcommerceProduct
| SiteBlockEcommerceProductList
| SiteBlockLayout
| SiteBlockImageSlideshow
| SiteBlockStickyBar;

export enum PAGE_TYPES {
	PAGE_TYPE_DEFAULT = 'default',
	PAGE_TYPE_BLOG = 'blog',
	PAGE_TYPE_ECOMMERCE_PRODUCT = 'ecommerce-product',
	PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT = 'ecommerce-dynamic-product',
	PAGE_TYPE_PRIVATE = 'private',
}

/**
 * @strict
 */
export interface SitePageMeta {
	title?: string;
	description?: string;
	ogImageAlt?: string;
	ogImagePath?: string | null;
	ogImageOrigin?: AssetsOrigin | null;
	noindex?: boolean;
	password?: string;
	passwordDesign?: PasswordDesign;
	passwordBackText?: string;
	passwordButtonText?: string;
	passwordHeadingText?: string;
	passwordSubheadingText?: string;
	passwordPlaceholderText?: string;
	keywords?: string[];
	focusKeyword?: string;
}

/**
 * @strict
 */
export interface SiteDefaultPage {
	type: PAGE_TYPES.PAGE_TYPE_DEFAULT;
	name: string;
	slug: PageSlug;
	meta?: SitePageMeta;
	blocks: string[];
}

/**
 * @strict
 */
export interface SiteBlogPage {
	type: PAGE_TYPES.PAGE_TYPE_BLOG;
	date: string;
	name: string;
	slug: PageSlug;
	blocks: string[];
	isDraft: boolean;
	categories: string[];
	minutesToRead: Digits;
	coverImageAlt: string;
	coverImagePath: string;
	coverImageOrigin: AssetsOrigin;
	meta: SitePageMeta;
	isScheduled?: boolean;
}

// SiteEcommerceProductPage depreceted but still used in sites - remove later
export interface SiteEcommerceProductPage {
	type: PAGE_TYPES.PAGE_TYPE_ECOMMERCE_PRODUCT;
	meta?: object;
	name?: string;
	locale?: string;
	productId?: string;
	blocks?: string[];
}

/**
 * @strict
 */
export interface SiteEcommerceDynamicProductPage {
	type: PAGE_TYPES.PAGE_TYPE_ECOMMERCE_DYNAMIC_PRODUCT;
	blocks: string[];
	isDefault?: boolean;
	name?: string;
}

/**
 * @strict
 */
export interface SitePrivatePage {
	type: PAGE_TYPES.PAGE_TYPE_PRIVATE;
	meta: SitePageMeta,
	name: string;
	blocks: string[];
	footerSlotIsHidden: boolean;
}

/**
 * @discriminator type
 */
export type SitePage = SiteDefaultPage | SiteBlogPage | SiteEcommerceDynamicProductPage | SitePrivatePage | SiteEcommerceProductPage;

/**
 * @strict
 */
export interface SiteNavPageItem {
	isHidden?: boolean;
	linkType: NAVIGATION_TYPE.PAGE;
	navItemId: NotEmptyString;
	linkedPageId: NotEmptyString;
	subItems?: unknown[];
}

/**
 * @strict
 */
export interface SiteNavFolderItem {
	name: NotEmptyString;
	isHidden: boolean;
	linkType: NAVIGATION_TYPE.FOLDER;
	navItemId: NotEmptyString;
	subItems?: unknown[];
}

/**
 * @strict
 */
export interface SiteNavLinkItem {
	isHidden?: boolean;
	linkType: NAVIGATION_TYPE.LINK;
	navItemId: NotEmptyString;
	linkedPageId?: string;
	name: string;
	subItems?: unknown[];
	rel: 'nofollow' | '';
	target: '_blank' | '_self';
	innerLinkType: LINK_TYPE;
	innerLinkPageId: string;
	href: string;
}

/**
 * @discriminator linkType
 */
type SiteNavItemOptions = SiteNavPageItem | SiteNavFolderItem | SiteNavLinkItem;

// ts-to-zod doesn't support generics and referencing types before declaration lazy loads them breaking some refinement functionality
export interface SiteNavLinkBase {
	subItems?: SiteNavItemOptions[];
}

export type SiteNavItem = SiteNavLinkBase & SiteNavItemOptions;

export type SitePages = Record<KeyString, SitePage>;
export type SiteEcommerceProductPages = Record<KeyString, SiteEcommerceProductPage>;
export type SiteEcommerceDynamicProductPages = Record<KeyString, SiteEcommerceDynamicProductPage>;
export type SiteBlogPages = Record<KeyString, SiteBlogPage>;
export type SiteBlocks = Record<KeyString, SiteBlock>;
export type SiteBlocksBlogList = Record<KeyString, SiteBlockBlogList>;
/**
 * @discriminator type
 */
export type SiteElement = SiteElementGridButton
  | SiteElementGridStripeButton
  | SiteElementGridEcommerceButton
  | SiteElementGridMap
  | SiteElementGridVideo
  | SiteElementGridTextBox
  | SiteElementGridShape
  | SiteElementGridImage
  | SiteElementGridInstagramFeed
  | SiteElementGridSocialIcons
  | SiteElementGridGallery
  | SiteElementGridEmbed
  | SiteElementSearchBar
  | SiteElementGridForm;

export type SitePageWithSlug = Exclude<SitePage, SitePrivatePage | SiteEcommerceDynamicProductPage | SiteEcommerceProductPage>;
export type SiteElements = Record<KeyString, SiteElement>;

// ts-to-zod limitations makes it preferable to create a separate type
export type SiteLanguageLocale = 'system'
  | 'af'
  | 'sq'
  | 'ar'
  | 'az'
  | 'ms'
  | 'eu'
  | 'by'
  | 'bn'
  | 'bg'
  | 'bs'
  | 'ca'
  | 'cs'
  | 'da'
  | 'de'
  | 'et'
  | 'el'
  | 'en'
  | 'es'
  | 'fr'
  | 'gl'
  | 'gu'
  | 'my'
  | 'zh'
  | 'zh'
  | 'ko'
  | 'hi'
  | 'kn'
  | 'hr'
  | 'id'
  | 'is'
  | 'it'
  | 'he'
  | 'ka'
  | 'ku'
  | 'lv'
  | 'lb'
  | 'lt'
  | 'hu'
  | 'mk'
  | 'mt'
  | 'mo'
  | 'mn'
  | 'mr'
  | 'ro'
  | 'ja'
  | 'nl'
  | 'no'
  | 'pl'
  | 'pt'
  | 'pa'
  | 'ru'
  | 'sr'
  | 'sk'
  | 'lk'
  | 'sd'
  | 'sl'
  | 'sv'
  | 'fi'
  | 'fas'
  | 'ta'
  | 'te'
  | 'th'
  | 'vi'
  | 'tr'
  | 'tk'
  | 'uk'
  | 'ur'
  | 'kk'
  | 'ky'
  | 'hy'
  | 'dv';

export const DEFAULT_HTML_LANG_VALUE = {
	title: 'English',
	value: 'en',
} as const;

export const HTML_LANG_VALUES = [
	DEFAULT_HTML_LANG_VALUE,
	{
		title: 'Abkhazian',
		value: 'ab',
	},
	{
		title: 'Afar',
		value: 'aa',
	},
	{
		title: 'Akan',
		value: 'ak',
	},
	{
		title: 'Aragonese',
		value: 'an',
	},
	{
		title: 'Assamese',
		value: 'as',
	},
	{
		title: 'Avaric',
		value: 'av',
	},
	{
		title: 'Avestan',
		value: 'ae',
	},
	{
		title: 'Aymara',
		value: 'ay',
	},
	{
		title: 'Bambara',
		value: 'bm',
	},
	{
		title: 'Bashkir',
		value: 'ba',
	},
	{
		title: 'Bihari',
		value: 'bh',
	},
	{
		title: 'Bislama',
		value: 'bi',
	},
	{
		title: 'Breton',
		value: 'br',
	},
	{
		title: 'Chamorro',
		value: 'ch',
	},
	{
		title: 'Chechen',
		value: 'ce',
	},
	{
		title: 'Chichewa, Chewa, Nyanja',
		value: 'ny',
	},
	{
		title: 'Chuvash',
		value: 'cv',
	},
	{
		title: 'Cornish',
		value: 'kw',
	},
	{
		title: 'Cree',
		value: 'cr',
	},
	{
		title: 'Divehi, Dhivehi, Maldivian',
		value: 'dv',
	},
	{
		title: 'Dzongkha',
		value: 'dz',
	},
	{
		title: 'Ewe',
		value: 'ee',
	},
	{
		title: 'Faroese',
		value: 'fo',
	},
	{
		title: 'Fijian',
		value: 'fj',
	},
	{
		title: 'Fula, Fulah, Pulaar, Pular',
		value: 'ff',
	},
	{
		title: 'Greenlandic, Kalaallisut',
		value: 'kl',
	},
	{
		title: 'Guarani',
		value: 'gn',
	},
	{
		title: 'Haitian Creole',
		value: 'ht',
	},
	{
		title: 'Herero',
		value: 'hz',
	},
	{
		title: 'Hiri Motu',
		value: 'ho',
	},
	{
		title: 'Ido',
		value: 'io',
	},
	{
		title: 'Igbo',
		value: 'ig',
	},
	{
		title: 'Interlingua',
		value: 'ia',
	},
	{
		title: 'Interlingue',
		value: 'ie',
	},
	{
		title: 'Inuktitut',
		value: 'iu',
	},
	{
		title: 'Inupiak',
		value: 'ik',
	},
	{
		title: 'Kashmiri',
		value: 'ks',
	},
	{
		title: 'Kikuyu',
		value: 'ki',
	},
	{
		title: 'Kinyarwanda (Rwanda)',
		value: 'rw',
	},
	{
		title: 'Kirundi',
		value: 'rn',
	},
	{
		title: 'Komi',
		value: 'kv',
	},
	{
		title: 'Kongo',
		value: 'kg',
	},
	{
		title: 'Kwanyama',
		value: 'kj',
	},
	{
		title: 'Latin',
		value: 'la',
	},
	{
		title: 'Limburgish (Limburger)',
		value: 'li',
	},
	{
		title: 'Lingala',
		value: 'ln',
	},
	{
		title: 'Luga-Katanga',
		value: 'lu',
	},
	{
		title: 'Luganda, Ganda',
		value: 'lg',
	},
	{
		title: 'Gaelic, Manx',
		value: 'gv',
	},
	{
		title: 'Marshallese',
		value: 'mh',
	},
	{
		title: 'Moldavian',
		value: 'mo',
	},
	{
		title: 'Nauru',
		value: 'na',
	},
	{
		title: 'Navajo',
		value: 'nv',
	},
	{
		title: 'Ndonga',
		value: 'ng',
	},
	{
		title: 'Northern Ndebele',
		value: 'nd',
	},
	{
		title: 'Norwegian nynorsk',
		value: 'nn',
	},
	{
		title: 'Nuosu, Sichuan Yi',
		value: 'ii',
	},
	{
		title: 'Occitan',
		value: 'oc',
	},
	{
		title: 'Ojibwe',
		value: 'oj',
	},
	{
		title: 'Old Church Slavonic, Old Bulgarian',
		value: 'cu',
	},
	{
		title: 'Oriya',
		value: 'or',
	},
	{
		title: 'Oromo (Afaan Oromo)',
		value: 'om',
	},
	{
		title: 'Ossetian',
		value: 'os',
	},
	{
		title: 'Pāli',
		value: 'pi',
	},
	{
		title: 'Quechua',
		value: 'qu',
	},
	{
		title: 'Romansh',
		value: 'rm',
	},
	{
		title: 'Sami',
		value: 'se',
	},
	{
		title: 'Sango',
		value: 'sg',
	},
	{
		title: 'Sanskrit',
		value: 'sa',
	},
	{
		title: 'Serbo-Croatian',
		value: 'sh',
	},
	{
		title: 'Setswana',
		value: 'tn',
	},
	{
		title: 'Southern Ndebele',
		value: 'nr',
	},
	{
		title: 'Swati, Siswati',
		value: 'ss',
	},
	{
		title: 'Tagalog',
		value: 'tl',
	},
	{
		title: 'Tahitian',
		value: 'ty',
	},
	{
		title: 'Tibetan',
		value: 'bo',
	},
	{
		title: 'Tigrinya',
		value: 'ti',
	},
	{
		title: 'Tonga',
		value: 'to',
	},
	{
		title: 'Tsonga',
		value: 'ts',
	},
	{
		title: 'Turkmen',
		value: 'tk',
	},
	{
		title: 'Twi',
		value: 'tw',
	},
	{
		title: 'Uyghur',
		value: 'ug',
	},
	{
		title: 'Venda',
		value: 've',
	},
	{
		title: 'Volapük',
		value: 'vo',
	},
	{
		title: 'Wallon',
		value: 'wa',
	},
	{
		title: 'Wolof',
		value: 'wo',
	},
	{
		title: 'Yiddish',
		value: 'yi, ji',
	},
	{
		title: 'Zhuang, Chuang',
		value: 'za',
	},
	{
		title: 'Afrikaans',
		value: 'af',
	},
	{
		title: 'Albanian',
		value: 'sq',
	},
	{
		title: 'Albanian (Albania)',
		value: 'sq-AL',
	},
	{
		title: 'Amharic',
		value: 'am',
	},
	{
		title: 'Arabic',
		value: 'ar',
	},
	{
		title: 'Arabic (Bahrain)',
		value: 'ar-BH',
	},
	{
		title: 'Arabic (Egypt)',
		value: 'ar-EG',
	},
	{
		title: 'Arabic (Jordan)',
		value: 'ar-JO',
	},
	{
		title: 'Arabic (Kuwait)',
		value: 'ar-KW',
	},
	{
		title: 'Arabic (Oman)',
		value: 'ar-OM',
	},
	{
		title: 'Arabic (Qatar)',
		value: 'ar-QA',
	},
	{
		title: 'Arabic (Saudi Arabia)',
		value: 'ar-SA',
	},
	{
		title: 'Arabic (Tunisia)',
		value: 'ar-TN',
	},
	{
		title: 'Arabic (United Arab Emirates)',
		value: 'ar-AE',
	},
	{
		title: 'Armenian',
		value: 'hy',
	},
	{
		title: 'Armenian (Armenia)',
		value: 'hy-AM',
	},
	{
		title: 'Azerbaijani',
		value: 'az',
	},
	{
		title: 'Basque',
		value: 'eu',
	},
	{
		title: 'Belarusian',
		value: 'be',
	},
	{
		title: 'Belarusian (Belarus)',
		value: 'be-BY',
	},
	{
		title: 'Bengali',
		value: 'bn',
	},
	{
		title: 'Bosnian',
		value: 'bs',
	},
	{
		title: 'Bosnian (Bosnia and Herzegovina)',
		value: 'bs-BA',
	},
	{
		title: 'Bulgarian',
		value: 'bg',
	},
	{
		title: 'Burmese',
		value: 'my',
	},
	{
		title: 'Burmese (Myanmar)',
		value: 'my-MM',
	},
	{
		title: 'Catalan',
		value: 'ca',
	},
	{
		title: 'Chinese',
		value: 'zh',
	},
	{
		title: 'Chinese - Simplified',
		value: 'zh-Hans',
	},
	{
		title: 'Chinese - Traditional',
		value: 'zh-Hant',
	},
	{
		title: 'Chinese (China) - Simplified',
		value: 'zh-CN',
	},
	{
		title: 'Chinese (Hong Kong)',
		value: 'zh-HK',
	},
	{
		title: 'Chinese (Taiwan) - Traditional',
		value: 'zh-TW',
	},
	{
		title: 'Corsican',
		value: 'co',
	},
	{
		title: 'Croatian',
		value: 'hr',
	},
	{
		title: 'Czech',
		value: 'cs',
	},
	{
		title: 'Czech (Czech Republic)',
		value: 'cs-CZ',
	},
	{
		title: 'Danish',
		value: 'da',
	},
	{
		title: 'Danish (Denmark)',
		value: 'da-DK',
	},
	{
		title: 'Dutch',
		value: 'nl',
	},
	{
		title: 'Dutch (Flemish (Belgium)',
		value: 'nl-BE',
	},
	{
		title: 'English (Armenia)',
		value: 'en-AM',
	},
	{
		title: 'English (Australian)',
		value: 'en-AU',
	},
	{
		title: 'English (Bahrain)',
		value: 'en-BH',
	},
	{
		title: 'English (Belgium)',
		value: 'en-BE',
	},
	{
		title: 'English (Botswana)',
		value: 'en-BW',
	},
	{
		title: 'English (British)',
		value: 'en-GB',
	},
	{
		title: 'English (Brunei)',
		value: 'en-BN',
	},
	{
		title: 'English (Cambodia)',
		value: 'en-KH',
	},
	{
		title: 'English (Canadian)',
		value: 'en-CA',
	},
	{
		title: 'English (China)',
		value: 'en-CN',
	},
	{
		title: 'English (Cyprus)',
		value: 'en-CY',
	},
	{
		title: 'English (Denmark)',
		value: 'en-DK',
	},
	{
		title: 'English (Egypt)',
		value: 'en-EG',
	},
	{
		title: 'English (Georgia)',
		value: 'en-GE',
	},
	{
		title: 'English (Hong Kong)',
		value: 'en-HK',
	},
	{
		title: 'English (Iceland)',
		value: 'en-IS',
	},
	{
		title: 'English (Indian)',
		value: 'en-IN',
	},
	{
		title: 'English (Indonesia)',
		value: 'en-ID',
	},
	{
		title: 'English (Iran)',
		value: 'en-IR',
	},
	{
		title: 'English (Ireland)',
		value: 'en-IE',
	},
	{
		title: 'English (Japan)',
		value: 'en-JP',
	},
	{
		title: 'English (Jordan)',
		value: 'en-JO',
	},
	{
		title: 'English (Kenya)',
		value: 'en-KE',
	},
	{
		title: 'English (Korea)',
		value: 'en-KR',
	},
	{
		title: 'English (Kuwait)',
		value: 'en-KW',
	},
	{
		title: 'English (Lebanon)',
		value: 'en-LB',
	},
	{
		title: 'English (Malaysia)',
		value: 'en-MY',
	},
	{
		title: 'English (Malta)',
		value: 'en-MT',
	},
	{
		title: 'English (Mauritius)',
		value: 'en-MU',
	},
	{
		title: 'English (Mozambique)',
		value: 'en-MZ',
	},
	{
		title: 'English (Myanmar)',
		value: 'en-MM',
	},
	{
		title: 'English (Namibia)',
		value: 'en-NA',
	},
	{
		title: 'English (Netherlands)',
		value: 'en-NL',
	},
	{
		title: 'English (New Zealand)',
		value: 'en-NZ',
	},
	{
		title: 'English (Norway)',
		value: 'en-NO',
	},
	{
		title: 'English (Oman)',
		value: 'en-OM',
	},
	{
		title: 'English (Pakistan)',
		value: 'en-PK',
	},
	{
		title: 'English (Philippines)',
		value: 'en-PH',
	},
	{
		title: 'English (Puerto Rico)',
		value: 'en-PR',
	},
	{
		title: 'English (Qatar)',
		value: 'en-QA',
	},
	{
		title: 'English (Russian Federation)',
		value: 'en-RU',
	},
	{
		title: 'English (Saudi Arabia)',
		value: 'en-SA',
	},
	{
		title: 'English (Singapore)',
		value: 'en-SG',
	},
	{
		title: 'English (South Africa)',
		value: 'en-ZA',
	},
	{
		title: 'English (Sri Lanka)',
		value: 'en-LK',
	},
	{
		title: 'English (Sweden)',
		value: 'en-SE',
	},
	{
		title: 'English (Switzerland)',
		value: 'en-CH',
	},
	{
		title: 'English (Thailand)',
		value: 'en-TH',
	},
	{
		title: 'English (U.S.)',
		value: 'en-US',
	},
	{
		title: 'English (United Arab Emirates)',
		value: 'en-AE',
	},
	{
		title: 'English (Vietnam)',
		value: 'en-VN',
	},
	{
		title: 'English (Zambia)',
		value: 'en-ZM',
	},
	{
		title: 'English (Zimbabwe)',
		value: 'en-ZW',
	},
	{
		title: 'Esperanto',
		value: 'eo',
	},
	{
		title: 'Estonian',
		value: 'et',
	},
	{
		title: 'Estonian (Estonia)',
		value: 'et-EE',
	},
	{
		title: 'Finnish',
		value: 'fi',
	},
	{
		title: 'French',
		value: 'fr',
	},
	{
		title: 'French (Belgium)',
		value: 'fr-BE',
	},
	{
		title: 'French (Canada)',
		value: 'fr-CA',
	},
	{
		title: 'French (Luxembourg)',
		value: 'fr-LU',
	},
	{
		title: 'French (Morocco)',
		value: 'fr-MA',
	},
	{
		title: 'French (Switzerland)',
		value: 'fr-CH',
	},
	{
		title: 'French (Tunisia)',
		value: 'fr-TN',
	},
	{
		title: 'Frisian (Netherlands)',
		value: 'fy-NL',
	},
	{
		title: 'Gaelic',
		value: 'gd',
	},
	{
		title: 'Galician',
		value: 'gl',
	},
	{
		title: 'Georgian',
		value: 'ka',
	},
	{
		title: 'Georgian (Georgia)',
		value: 'ka-GE',
	},
	{
		title: 'German',
		value: 'de',
	},
	{
		title: 'German (Austria)',
		value: 'de-AT',
	},
	{
		title: 'German (Switzerland)',
		value: 'de-CH',
	},
	{
		title: 'Greek',
		value: 'el',
	},
	{
		title: 'Greek (Cyprus)',
		value: 'el-CY',
	},
	{
		title: 'Greek (Greece)',
		value: 'el-GR',
	},
	{
		title: 'Gujarati',
		value: 'gu',
	},
	{
		title: 'Hausa',
		value: 'ha',
	},
	{
		title: 'Hebrew',
		value: 'he',
	},
	{
		title: 'Hebrew (modern) (Israel)',
		value: 'he-IL',
	},
	{
		title: 'Hindi',
		value: 'hi',
	},
	{
		title: 'Hungarian',
		value: 'hu',
	},
	{
		title: 'Icelandic',
		value: 'is',
	},
	{
		title: 'Indonesian',
		value: 'id',
	},
	{
		title: 'Irish',
		value: 'ga',
	},
	{
		title: 'Irish (Ireland)',
		value: 'ga-IE',
	},
	{
		title: 'Italian',
		value: 'it',
	},
	{
		title: 'Italian (Switzerland)',
		value: 'it-CH',
	},
	{
		title: 'Japanese',
		value: 'ja',
	},
	{
		title: 'Japanese (Japan)',
		value: 'ja-JP',
	},
	{
		title: 'Javanese',
		value: 'jv',
	},
	{
		title: 'Kannada',
		value: 'kn',
	},
	{
		title: 'Kanuri',
		value: 'kr',
	},
	{
		title: 'Kazakh',
		value: 'kk',
	},
	{
		title: 'Khmer',
		value: 'km',
	},
	{
		title: 'Khmer (Cambodia)',
		value: 'km-KH',
	},
	{
		title: 'Korean',
		value: 'ko',
	},
	{
		title: 'Korean (South Korea)',
		value: 'ko-KR',
	},
	{
		title: 'Kurdish',
		value: 'ku',
	},
	{
		title: 'Kyrgyz',
		value: 'ky',
	},
	{
		title: 'Lao',
		value: 'lo',
	},
	{
		title: 'Lao (Lao PDR)',
		value: 'lo-LA',
	},
	{
		title: 'Latvian',
		value: 'lv',
	},
	{
		title: 'Lithuanian',
		value: 'lt',
	},
	{
		title: 'Luxembourgish',
		value: 'lb',
	},
	{
		title: 'Macedonian',
		value: 'mk',
	},
	{
		title: 'Malagasy',
		value: 'mg',
	},
	{
		title: 'Malay',
		value: 'ms',
	},
	{
		title: 'Malay (Brunei)',
		value: 'ms-BN',
	},
	{
		title: 'Malayalam',
		value: 'ml',
	},
	{
		title: 'Maltese',
		value: 'mt',
	},
	{
		title: 'Maori',
		value: 'mi',
	},
	{
		title: 'Marathi',
		value: 'mr',
	},
	{
		title: 'Mongolian',
		value: 'mn',
	},
	{
		title: 'Montenegrin',
		value: 'cnr',
	},
	{
		title: 'Nepali',
		value: 'ne',
	},
	{
		title: 'Norwegian',
		value: 'no',
	},
	{
		title: 'Norwegian Bokmål',
		value: 'nb',
	},
	{
		title: 'Pashto',
		value: 'ps',
	},
	{
		title: 'Persian',
		value: 'fa',
	},
	{
		title: 'Persian (Iran)',
		value: 'fa-IR',
	},
	{
		title: 'Polish',
		value: 'pl',
	},
	{
		title: 'Portuguese',
		value: 'pt',
	},
	{
		title: 'Portuguese (Angola)',
		value: 'pt-AO',
	},
	{
		title: 'Portuguese (Brazil)',
		value: 'pt-BR',
	},
	{
		title: 'Punjabi',
		value: 'pa',
	},
	{
		title: 'Romanian',
		value: 'ro',
	},
	{
		title: 'Romanian (Moldova)',
		value: 'ro-MD',
	},
	{
		title: 'Russian',
		value: 'ru',
	},
	{
		title: 'Russian (Moldova)',
		value: 'ru-MD',
	},
	{
		title: 'Samoan',
		value: 'sm',
	},
	{
		title: 'Serbian',
		value: 'sr',
	},
	{
		title: 'Serbian (Suriname)',
		value: 'sr-RS',
	},
	{
		title: 'Shona',
		value: 'sn',
	},
	{
		title: 'Sindhi',
		value: 'sd',
	},
	{
		title: 'Sinhala',
		value: 'si',
	},
	{
		title: 'Sinhala (Sri Lanka)',
		value: 'si-LK',
	},
	{
		title: 'Slovak',
		value: 'sk',
	},
	{
		title: 'Slovenian',
		value: 'sl',
	},
	{
		title: 'Somali',
		value: 'so',
	},
	{
		title: 'Southern Sotho',
		value: 'st',
	},
	{
		title: 'Spanish',
		value: 'es',
	},
	{
		title: 'Spanish (Argentina)',
		value: 'es-AR',
	},
	{
		title: 'Spanish (Belize)',
		value: 'es-BZ',
	},
	{
		title: 'Spanish (Bolivia)',
		value: 'es-BO',
	},
	{
		title: 'Spanish (Chile)',
		value: 'es-CL',
	},
	{
		title: 'Spanish (Cocos (Keeling) Islands)',
		value: 'es-CC',
	},
	{
		title: 'Spanish (Colombia)',
		value: 'es-CO',
	},
	{
		title: 'Spanish (Costa Rica)',
		value: 'es-CR',
	},
	{
		title: 'Spanish (Dominican Republic)',
		value: 'es-DO',
	},
	{
		title: 'Spanish (Ecuador)',
		value: 'es-EC',
	},
	{
		title: 'Spanish (El Salvador)',
		value: 'es-SV',
	},
	{
		title: 'Spanish (Guatemala)',
		value: 'es-GT',
	},
	{
		title: 'Spanish (Honduras)',
		value: 'es-HN',
	},
	{
		title: 'Spanish (Mexico)',
		value: 'es-MX',
	},
	{
		title: 'Spanish (Nicaragua)',
		value: 'es-NI',
	},
	{
		title: 'Spanish (Panama)',
		value: 'es-PA',
	},
	{
		title: 'Spanish (Paraguay)',
		value: 'es-PY',
	},
	{
		title: 'Spanish (Peru)',
		value: 'es-PE',
	},
	{
		title: 'Spanish (Puerto Rico)',
		value: 'es-PR',
	},
	{
		title: 'Spanish (Uruguay)',
		value: 'es-UY',
	},
	{
		title: 'Spanish (Venezuela)',
		value: 'es-VE',
	},
	{
		title: 'Sundanese',
		value: 'su',
	},
	{
		title: 'Swahili',
		value: 'sw',
	},
	{
		title: 'Swedish',
		value: 'sv',
	},
	{
		title: 'Swedish (Sweden)',
		value: 'sv-SE',
	},
	{
		title: 'Tajik',
		value: 'tg',
	},
	{
		title: 'Tamil',
		value: 'ta',
	},
	{
		title: 'Tatar',
		value: 'tt',
	},
	{
		title: 'Telugu',
		value: 'te',
	},
	{
		title: 'Thai',
		value: 'th',
	},
	{
		title: 'Turkish',
		value: 'tr',
	},
	{
		title: 'Ukrainian',
		value: 'uk',
	},
	{
		title: 'Ukrainian (Ukraine)',
		value: 'uk-UA',
	},
	{
		title: 'Urdu',
		value: 'ur',
	},
	{
		title: 'Uzbek',
		value: 'uz',
	},
	{
		title: 'Vietnamese',
		value: 'vi',
	},
	{
		title: 'Vietnamese (Vietnam)',
		value: 'vi-VN',
	},
	{
		title: 'Welsh',
		value: 'cy',
	},
	{
		title: 'Western Frisian',
		value: 'fy',
	},
	{
		title: 'Xhosa',
		value: 'xh',
	},
	{
		title: 'Yiddish',
		value: 'yi',
	},
	{
		title: 'Yoruba',
		value: 'yo',
	},
	{
		title: 'Zulu',
		value: 'zu',
	},
];

// ts-to-zod does not support mapped types, type number index access, etc so checks for types like country, locale are done in refinedSchema
export interface MultilingualSupportedLanguage {
  locale: SiteLanguageLocale;
  country: string;
  name: string;
  flagPath: NotEmptyString;
  alternateName: string;
  languageCode: string;
}

// When adding a new language:
// - Identify `locale` in https://en.wikipedia.org/wiki/List_of_ISO_639-2_codes (check 639-1 code)
// - Check wether API has language support: go to https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/4.1.5/flags/4x3/${locale}.svg. If not - leave empty.
// - Find `name` in https://www.internationalphoneticalphabet.org/languages/language-names-in-native-language/
// - Construct the entry (`{ locale, name, country, flagPath }`)
// - Add the entry in alphabetical order where it fits
// - Add locale specific translations for cookie banner in `USER_SITE_TRANSLATIONS` constant (use Google Translate)
export const MULTILINGUAL_SUPPORTED_LANGUAGES: MultilingualSupportedLanguage[] = [
	{
		locale: 'af',
		name: 'Afrikaans',
		country: 'za',
		flagPath: 'za.svg',
		alternateName: '',
		languageCode: 'af',
	},
	{
		locale: 'sq',
		name: 'Albanian',
		country: 'al',
		flagPath: 'al.svg',
		alternateName: '',
		languageCode: 'sq',
	},
	{
		locale: 'ar',
		name: 'العربية (DZ)',
		country: 'dz',
		flagPath: 'dz.svg',
		alternateName: 'Arabic',
		languageCode: '',
	},
	{
		locale: 'ar',
		name: 'العربية (BH)',
		country: 'bh',
		flagPath: 'bh.svg',
		alternateName: 'Arabic',
		languageCode: 'ar-BH',
	},
	{
		locale: 'ar',
		name: 'العربية (EG)',
		country: 'eg',
		flagPath: 'eg.svg',
		alternateName: 'Arabic',
		languageCode: 'ar-EG',
	},
	{
		locale: 'ar',
		name: 'العربية (IQ)',
		country: 'iq',
		flagPath: 'iq.svg',
		alternateName: 'Arabic',
		languageCode: '',
	},
	{
		locale: 'ar',
		name: 'العربية (JO)',
		country: 'jo',
		flagPath: 'jo.svg',
		alternateName: 'Arabic',
		languageCode: 'ar-JO',
	},
	{
		locale: 'ar',
		name: 'العربية (KW)',
		country: 'kw',
		flagPath: 'kw.svg',
		alternateName: 'Arabic',
		languageCode: 'ar-KW',
	},
	{
		locale: 'ar',
		name: 'العربية (LB)',
		country: 'lb',
		flagPath: 'lb.svg',
		alternateName: 'Arabic',
		languageCode: '',
	},
	{
		locale: 'ar',
		name: 'العربية (LY)',
		country: 'ly',
		flagPath: 'ly.svg',
		alternateName: 'Arabic',
		languageCode: '',
	},
	{
		locale: 'ar',
		name: 'العربية (MA)',
		country: 'ma',
		flagPath: 'ma.svg',
		alternateName: 'Arabic',
		languageCode: '',
	},
	{
		locale: 'ar',
		name: 'العربية (OM)',
		country: 'om',
		flagPath: 'om.svg',
		alternateName: 'Arabic',
		languageCode: 'ar-OM',
	},
	{
		locale: 'ar',
		name: 'العربية (QA)',
		country: 'qa',
		flagPath: 'qa.svg',
		alternateName: 'Arabic',
		languageCode: 'ar-QA',
	},
	{
		locale: 'ar',
		name: 'العربية (SA)',
		country: 'sa',
		flagPath: 'sa.svg',
		alternateName: 'Arabic',
		languageCode: 'ar-SA',
	},
	{
		locale: 'ar',
		name: 'العربية (SY)',
		country: 'sy',
		flagPath: 'sy.svg',
		alternateName: 'Arabic',
		languageCode: '',
	},
	{
		locale: 'ar',
		name: 'العربية (TN)',
		country: 'tn',
		flagPath: 'tn.svg',
		alternateName: 'Arabic',
		languageCode: 'ar-TN',
	},
	{
		locale: 'ar',
		name: 'العربية (AE)',
		country: 'ae',
		flagPath: 'ae.svg',
		alternateName: 'Arabic',
		languageCode: 'ar-AE',
	},
	{
		locale: 'ar',
		name: 'العربية (YE)',
		country: 'ye',
		flagPath: 'ye.svg',
		alternateName: 'Arabic',
		languageCode: '',
	},
	{
		locale: 'hy',
		name: 'Հայերեն',
		country: 'am',
		flagPath: 'am.svg',
		alternateName: 'Armenian',
		languageCode: 'hy',
	},
	{
		locale: 'az',
		name: 'Azərbaycanca',
		country: 'az',
		flagPath: 'az.svg',
		alternateName: 'Azerbaijani',
		languageCode: 'az',
	},
	{
		locale: 'ms',
		name: 'Bahasa Melayu (BN)',
		country: 'bn',
		flagPath: 'bn.svg',
		alternateName: 'Malay',
		languageCode: 'ms',
	},
	{
		locale: 'ms',
		name: 'Bahasa Melayu (ID)',
		country: 'id',
		flagPath: 'id.svg',
		alternateName: 'Malay',
		languageCode: 'ms',
	},
	{
		locale: 'ms',
		name: 'Bahasa Melayu (MY)',
		country: 'my',
		flagPath: 'my.svg',
		alternateName: 'Malay',
		languageCode: 'ms',
	},
	{
		locale: 'ms',
		name: 'Bahasa Melayu (SG)',
		country: 'sg',
		flagPath: 'sg.svg',
		alternateName: 'Malay',
		languageCode: 'ms',
	},
	{
		locale: 'eu',
		name: 'Euskara',
		country: 'es',
		flagPath: '',
		alternateName: 'Basque',
		languageCode: 'eu',
	},
	{
		locale: 'by',
		name: 'Беларуская',
		country: 'by',
		flagPath: 'by.svg',
		alternateName: 'Belarusian',
		languageCode: '',
	},
	{
		locale: 'bn',
		name: 'বাংলা',
		country: 'bd',
		flagPath: 'bd.svg',
		alternateName: 'Bengali',
		languageCode: 'bn',
	},
	{
		locale: 'bg',
		name: 'Български',
		country: 'bg',
		flagPath: 'bg.svg',
		alternateName: 'Bulgarian',
		languageCode: 'bg',
	},
	{
		locale: 'bs',
		name: 'Bosanski',
		country: 'ba',
		flagPath: 'ba.svg',
		alternateName: 'Bosnian',
		languageCode: 'bs',
	},
	{
		locale: 'ca',
		name: 'Català (ES)',
		country: 'es',
		flagPath: 'es-ct.svg',
		alternateName: 'Catalan',
		languageCode: 'ca',
	},
	{
		locale: 'ca',
		name: 'Català (AD)',
		country: 'ad',
		flagPath: 'ad.svg',
		alternateName: 'Catalan',
		languageCode: 'ca',
	},
	{
		locale: 'cs',
		name: 'Čeština',
		country: 'cz',
		flagPath: 'cz.svg',
		alternateName: 'Czech',
		languageCode: 'cs',
	},
	{
		locale: 'da',
		name: 'Dansk',
		country: 'dk',
		flagPath: 'dk.svg',
		alternateName: 'Danish',
		languageCode: 'da',
	},
	{
		locale: 'de',
		name: 'Deutsch (AT)',
		country: 'at',
		flagPath: 'at.svg',
		alternateName: '',
		languageCode: 'de-AT',
	},
	{
		locale: 'de',
		name: 'Deutsch (LI)',
		country: 'li',
		flagPath: 'li.svg',
		alternateName: '',
		languageCode: '',
	},
	{
		locale: 'de',
		name: 'Deutsch (LU)',
		country: 'lu',
		flagPath: 'lu.svg',
		alternateName: '',
		languageCode: '',
	},
	{
		locale: 'de',
		name: 'Deutsch (DE)',
		country: 'de',
		flagPath: 'de.svg',
		alternateName: '',
		languageCode: '',
	},
	{
		locale: 'de',
		name: 'Deutsch (CH)',
		country: 'ch',
		flagPath: 'ch.svg',
		alternateName: '',
		languageCode: 'de-CH',
	},
	{
		locale: 'et',
		name: 'Eesti',
		country: 'ee',
		flagPath: 'ee.svg',
		alternateName: 'Estonian',
		languageCode: 'et',
	},
	{
		locale: 'el',
		name: 'Ελληνικά',
		country: 'gr',
		flagPath: 'gr.svg',
		alternateName: 'Greek',
		languageCode: 'el-GR',
	},
	{
		locale: 'en',
		name: 'English (PH)',
		country: 'ph',
		flagPath: 'ph.svg',
		alternateName: 'English (PH)',
		languageCode: 'en-PH',
	},
	{
		locale: 'en',
		name: 'English (US)',
		country: 'us',
		flagPath: 'us.svg',
		alternateName: '',
		languageCode: 'en-US',
	},
	{
		locale: 'en',
		name: 'English (AU)',
		country: 'au',
		flagPath: 'au.svg',
		alternateName: '',
		languageCode: 'en-AU',
	},
	{
		locale: 'en',
		name: 'English (BZ)',
		country: 'bz',
		flagPath: 'bz.svg',
		alternateName: '',
		languageCode: '',
	},
	{
		locale: 'en',
		name: 'English (CA)',
		country: 'ca',
		flagPath: 'ca.svg',
		alternateName: '',
		languageCode: 'en-CA',
	},
	{
		locale: 'en',
		name: 'English (IE)',
		country: 'ie',
		flagPath: 'ie.svg',
		alternateName: '',
		languageCode: 'en-IE',
	},
	{
		locale: 'en',
		name: 'English (JM)',
		country: 'jm',
		flagPath: 'jm.svg',
		alternateName: '',
		languageCode: '',
	},
	{
		locale: 'en',
		name: 'English (NZ)',
		country: 'nz',
		flagPath: 'nz.svg',
		alternateName: '',
		languageCode: 'en-NZ',
	},
	{
		locale: 'en',
		name: 'English (ZA)',
		country: 'za',
		flagPath: 'za.svg',
		alternateName: '',
		languageCode: 'en-ZA',
	},
	{
		locale: 'en',
		name: 'English (TT)',
		country: 'tt',
		flagPath: 'tt.svg',
		alternateName: '',
		languageCode: '',
	},
	{
		locale: 'en',
		name: 'English (GB)',
		country: 'gb',
		flagPath: 'gb.svg',
		alternateName: '',
		languageCode: 'en-GB',
	},
	{
		locale: 'es',
		name: 'Español (AR)',
		country: 'ar',
		flagPath: 'ar.svg',
		alternateName: 'Spanish',
		languageCode: 'es-AR',
	},
	{
		locale: 'es',
		name: 'Español (BO)',
		country: 'bo',
		flagPath: 'bo.svg',
		alternateName: 'Spanish',
		languageCode: 'es-BO',
	},
	{
		locale: 'es',
		name: 'Español (CL)',
		country: 'cl',
		flagPath: 'cl.svg',
		alternateName: 'Spanish',
		languageCode: 'es-CL',
	},
	{
		locale: 'es',
		name: 'Español (CO)',
		country: 'co',
		flagPath: 'co.svg',
		alternateName: 'Spanish',
		languageCode: 'es-CO',
	},
	{
		locale: 'es',
		name: 'Español (CR)',
		country: 'cr',
		flagPath: 'cr.svg',
		alternateName: 'Spanish',
		languageCode: 'es-CR',
	},
	{
		locale: 'es',
		name: 'Español (DO)',
		country: 'do',
		flagPath: 'do.svg',
		alternateName: 'Spanish',
		languageCode: 'es-DO',
	},
	{
		locale: 'es',
		name: 'Español (EC)',
		country: 'ec',
		flagPath: 'ec.svg',
		alternateName: 'Spanish',
		languageCode: 'es-EC',
	},
	{
		locale: 'es',
		name: 'Español (SV)',
		country: 'sv',
		flagPath: 'sv.svg',
		alternateName: 'Spanish',
		languageCode: 'es-SV',
	},
	{
		locale: 'es',
		name: 'Español (GT)',
		country: 'gt',
		flagPath: 'gt.svg',
		alternateName: 'Spanish',
		languageCode: 'es-GT',
	},
	{
		locale: 'es',
		name: 'Español (HN)',
		country: 'hn',
		flagPath: 'hn.svg',
		alternateName: 'Spanish',
		languageCode: 'es-HN',
	},
	{
		locale: 'es',
		name: 'Español (MX)',
		country: 'mx',
		flagPath: 'mx.svg',
		alternateName: 'Spanish',
		languageCode: 'es-MX',
	},
	{
		locale: 'es',
		name: 'Español (NI)',
		country: 'ni',
		flagPath: 'ni.svg',
		alternateName: 'Spanish',
		languageCode: 'es-NI',
	},
	{
		locale: 'es',
		name: 'Español (PA)',
		country: 'pa',
		flagPath: 'pa.svg',
		alternateName: 'Spanish',
		languageCode: 'es-PA',
	},
	{
		locale: 'es',
		name: 'Español (PY)',
		country: 'py',
		flagPath: 'py.svg',
		alternateName: 'Spanish',
		languageCode: 'es-PY',
	},
	{
		locale: 'es',
		name: 'Español (PE)',
		country: 'pe',
		flagPath: 'pe.svg',
		alternateName: 'Spanish',
		languageCode: 'es-PE',
	},
	{
		locale: 'es',
		name: 'Español (PR)',
		country: 'pr',
		flagPath: 'pr.svg',
		alternateName: 'Spanish',
		languageCode: 'es-PR',
	},
	{
		locale: 'es',
		name: 'Español (ES)',
		country: 'es',
		flagPath: 'es.svg',
		alternateName: 'Spanish',
		languageCode: '',
	},
	{
		locale: 'es',
		name: 'Español (UY)',
		country: 'uy',
		flagPath: 'uy.svg',
		alternateName: 'Spanish',
		languageCode: 'es-UY',
	},
	{
		locale: 'es',
		name: 'Español (VE)',
		country: 've',
		flagPath: 've.svg',
		alternateName: 'Spanish',
		languageCode: 'es-VE',
	},
	{
		locale: 'fr',
		name: 'Français (BE)',
		country: 'be',
		flagPath: 'be.svg',
		alternateName: 'French',
		languageCode: 'fr-BE',
	},
	{
		locale: 'fr',
		name: 'Français (CA)',
		country: 'ca',
		flagPath: 'ca.svg',
		alternateName: 'French',
		languageCode: 'fr-CA',
	},
	{
		locale: 'fr',
		name: 'Français (LU)',
		country: 'lu',
		flagPath: 'lu.svg',
		alternateName: 'French',
		languageCode: 'fr-LU',
	},
	{
		locale: 'fr',
		name: 'Français (FR)',
		country: 'fr',
		flagPath: 'fr.svg',
		alternateName: 'French',
		languageCode: '',
	},
	{
		locale: 'fr',
		name: 'Français (CH)',
		country: 'ch',
		flagPath: 'ch.svg',
		alternateName: 'French',
		languageCode: 'fr-CH',
	},
	{
		locale: 'gl',
		name: 'Galego (GL)',
		country: 'pt',
		flagPath: 'pt.svg',
		alternateName: 'Galician',
		languageCode: 'gl',
	},
	{
		locale: 'gu',
		name: 'ગુજરાતી (IN)',
		country: 'in',
		flagPath: 'in.svg',
		alternateName: 'Gujarati',
		languageCode: 'gu',
	},
	{
		locale: 'my',
		name: 'မြန်မာဘာသာ (MM)',
		country: 'mm',
		flagPath: 'mm.svg',
		alternateName: 'Burmese',
		languageCode: 'my',
	},
	{
		locale: 'zh',
		name: '汉语 (HK)',
		country: 'hk',
		flagPath: 'hk.svg',
		alternateName: 'Chinese',
		languageCode: 'zh-HK',
	},
	{
		locale: 'zh',
		name: '汉语 (CN)',
		country: 'cn',
		flagPath: 'cn.svg',
		alternateName: 'Chinese',
		languageCode: 'zh-CN',
	},
	{
		locale: 'zh',
		name: '汉语 (SG)',
		country: 'sg',
		flagPath: 'sg.svg',
		alternateName: 'Chinese',
		languageCode: '',
	},
	{
		locale: 'zh',
		name: '汉语 (TW)',
		country: 'tw',
		flagPath: 'tw.svg',
		alternateName: 'Chinese',
		languageCode: 'zh-TW',
	},
	{
		locale: 'zh',
		name: '中文 (HK)',
		country: 'hk',
		flagPath: 'hk.svg',
		alternateName: 'Chinese',
		languageCode: 'zh-HK',
	},
	{
		locale: 'zh',
		name: '中文 (TW)',
		country: 'tw',
		flagPath: 'tw.svg',
		alternateName: 'Chinese',
		languageCode: 'zh-TW',
	},
	{
		locale: 'ko',
		name: '한국어 (KR)',
		country: 'kr',
		flagPath: 'kr.svg',
		alternateName: 'Korean',
		languageCode: 'ko',
	},
	{
		locale: 'ko',
		name: '한국어 (KP)',
		country: 'kp',
		flagPath: 'kp.svg',
		alternateName: 'Korean',
		languageCode: 'ko',
	},
	{
		locale: 'hi',
		name: 'हिन्दी',
		country: 'in',
		flagPath: 'in.svg',
		alternateName: 'Hindi',
		languageCode: 'hi',
	},
	{
		locale: 'kn',
		name: 'ಕನ್ನಡ',
		country: 'in',
		flagPath: 'in.svg',
		alternateName: 'Kannada',
		languageCode: 'kn',
	},
	{
		locale: 'hr',
		name: 'Hrvatski',
		country: 'hr',
		flagPath: 'hr.svg',
		alternateName: 'Croatian',
		languageCode: 'hr',
	},
	{
		locale: 'id',
		name: 'Bahasa Indonesia',
		country: 'id',
		flagPath: 'id.svg',
		alternateName: 'Indonesian',
		languageCode: 'id',
	},
	{
		locale: 'is',
		name: 'Íslenska',
		country: 'is',
		flagPath: 'is.svg',
		alternateName: 'Icelandic',
		languageCode: 'is',
	},
	{
		locale: 'it',
		name: 'Italiano (IT)',
		country: 'it',
		flagPath: 'it.svg',
		alternateName: 'Italian',
		languageCode: 'it',
	},
	{
		locale: 'it',
		name: 'Italiano (CH)',
		country: 'ch',
		flagPath: 'ch.svg',
		alternateName: 'Italian',
		languageCode: 'it',
	},
	{
		locale: 'he',
		name: 'עברית',
		country: 'il',
		flagPath: 'il.svg',
		alternateName: 'Hebrew',
		languageCode: 'he',
	},
	{
		locale: 'ka',
		name: 'ქართული',
		country: 'ge',
		flagPath: 'ge.svg',
		alternateName: 'Georgian',
		languageCode: 'ka',
	},
	{
		locale: 'ku',
		name: 'Kurdî (TR)',
		country: 'tr',
		flagPath: 'tr.svg',
		alternateName: 'Kurdish',
		languageCode: 'ku',
	},
	{
		locale: 'ku',
		name: 'Kurdî (IR)',
		country: 'ir',
		flagPath: 'ir.svg',
		alternateName: 'Kurdish',
		languageCode: 'ku',
	},
	{
		locale: 'ku',
		name: 'Kurdî (IQ)',
		country: 'iq',
		flagPath: 'iq.svg',
		alternateName: 'Kurdish',
		languageCode: 'ku',
	},
	{
		locale: 'ku',
		name: 'Kurdî (SY)',
		country: 'sy',
		flagPath: 'sy.svg',
		alternateName: 'Kurdish',
		languageCode: 'ku',
	},
	{
		locale: 'lv',
		name: 'Latviešu',
		country: 'lv',
		flagPath: 'lv.svg',
		alternateName: 'Latvian',
		languageCode: 'lv',
	},
	{
		locale: 'lb',
		name: 'Lëtzebuergesch',
		country: 'lu',
		flagPath: 'lu.svg',
		alternateName: 'Luxembourgish',
		languageCode: 'lb',
	},
	{
		locale: 'lt',
		name: 'Lietuviškai',
		country: 'lt',
		flagPath: 'lt.svg',
		alternateName: 'Lithuanian',
		languageCode: 'lt',
	},
	{
		locale: 'hu',
		name: 'Magyar',
		country: 'hu',
		flagPath: 'hu.svg',
		alternateName: 'Hungarian',
		languageCode: 'hu',
	},
	{
		locale: 'mk',
		name: 'Македонски',
		country: 'mk',
		flagPath: 'mk.svg',
		alternateName: 'Macedonian',
		languageCode: 'mk',
	},
	{
		locale: 'mt',
		name: 'Malti',
		country: 'mt',
		flagPath: 'mt.svg',
		alternateName: 'Maltese',
		languageCode: 'mt',
	},
	{
		locale: 'mo',
		name: 'Moldovenească',
		country: 'md',
		flagPath: 'md.svg',
		alternateName: 'Moldovan',
		languageCode: 'mo',
	},
	{
		locale: 'mn',
		name: 'Монгол',
		country: 'mn',
		flagPath: 'mn.svg',
		alternateName: 'Mongolian',
		languageCode: 'mn',
	},
	{
		locale: 'mr',
		name: 'मराठी',
		country: 'in',
		flagPath: 'in.svg',
		alternateName: 'Marathi',
		languageCode: 'mr',
	},
	{
		locale: 'ro',
		name: 'Română (RO)',
		country: 'ro',
		flagPath: 'ro.svg',
		alternateName: 'Romanian',
		languageCode: 'ro',
	},
	{
		locale: 'ro',
		name: 'Română (MD)',
		country: 'md',
		flagPath: 'md.svg',
		alternateName: 'Romanian',
		languageCode: 'ro',
	},
	{
		locale: 'ja',
		name: '日本語',
		country: 'jp',
		flagPath: 'jp.svg',
		alternateName: 'Japanese',
		languageCode: 'ja',
	},
	{
		locale: 'nl',
		name: 'Nederlands (BE)',
		country: 'be',
		flagPath: 'be.svg',
		alternateName: 'Dutch',
		languageCode: 'nl',
	},
	{
		locale: 'nl',
		name: 'Nederlands (NL)',
		country: 'nl',
		flagPath: 'nl.svg',
		alternateName: 'Dutch',
		languageCode: 'nl',
	},
	{
		locale: 'no',
		name: 'Norsk',
		country: 'no',
		flagPath: 'no.svg',
		alternateName: 'Norwegian',
		languageCode: 'no',
	},
	{
		locale: 'pl',
		name: 'Polski',
		country: 'pl',
		flagPath: 'pl.svg',
		alternateName: 'Polish',
		languageCode: 'pl',
	},
	{
		locale: 'pt',
		name: 'Português (BR)',
		country: 'br',
		flagPath: 'br.svg',
		alternateName: 'Portuguese',
		languageCode: 'pt-BR',
	},
	{
		locale: 'pt',
		name: 'Português (PT)',
		country: 'pt',
		flagPath: 'pt.svg',
		alternateName: 'Portuguese',
		languageCode: '',
	},
	{
		locale: 'pa',
		name: 'ਪੰਜਾਬੀ (IN)',
		country: 'in',
		flagPath: '',
		alternateName: 'Punjabi',
		languageCode: 'pa',
	},
	{
		locale: 'ru',
		name: 'Русский (RU)',
		country: 'ru',
		flagPath: 'ru.svg',
		alternateName: 'Russian',
		languageCode: 'ru',
	},
	{
		locale: 'ru',
		name: 'Русский (MD)',
		country: 'md',
		flagPath: 'md.svg',
		alternateName: 'Russian',
		languageCode: 'ru',
	},
	{
		locale: 'sr',
		name: 'Српски',
		country: 'rs',
		flagPath: 'rs.svg',
		alternateName: 'Serbian',
		languageCode: 'sr',
	},
	{
		locale: 'sk',
		name: 'Slovenčina',
		country: 'sk',
		flagPath: 'sk.svg',
		alternateName: 'Slovak',
		languageCode: 'sk',
	},
	{
		locale: 'lk',
		name: 'සිංහල',
		country: 'lk',
		flagPath: 'lk.svg',
		alternateName: 'Sinhala',
		languageCode: '',
	},
	{
		locale: 'sd',
		name: 'سنڌي (PK)',
		country: 'pk',
		flagPath: 'pk.svg',
		alternateName: 'Sindhi',
		languageCode: 'sd',
	},
	{
		locale: 'sd',
		name: 'سنڌي (IN)',
		country: 'in',
		flagPath: 'in.svg',
		alternateName: 'Sindhi',
		languageCode: 'sd',
	},
	{
		locale: 'sl',
		name: 'Slovene',
		country: 'si',
		flagPath: 'si.svg',
		alternateName: 'Slovene',
		languageCode: 'sl',
	},
	{
		locale: 'sv',
		name: 'Svenska (SE)',
		country: 'se',
		flagPath: 'se.svg',
		alternateName: 'Swedish',
		languageCode: 'sv',
	},
	{
		locale: 'sv',
		name: 'Svenska (FI)',
		country: 'fi',
		flagPath: 'fi.svg',
		alternateName: 'Swedish',
		languageCode: 'sv',
	},
	{
		locale: 'fi',
		name: 'Suomi',
		country: 'fi',
		flagPath: 'fi.svg',
		alternateName: 'Finnish',
		languageCode: 'fi',
	},
	{
		locale: 'fas',
		name: 'فارسی',
		country: 'ir',
		flagPath: 'ir.svg',
		alternateName: 'Persian',
		languageCode: '',
	},
	{
		locale: 'ta',
		name: 'தமிழ்',
		country: 'in',
		flagPath: 'in.svg',
		alternateName: 'Tamil',
		languageCode: 'ta',
	},
	{
		locale: 'te',
		name: 'తెలుగు',
		country: 'in',
		flagPath: 'in.svg',
		alternateName: 'Telugu',
		languageCode: 'te',
	},
	{
		locale: 'th',
		name: 'ภาษาไทย',
		country: 'th',
		flagPath: 'th.svg',
		alternateName: 'Thai',
		languageCode: 'th',
	},
	{
		locale: 'vi',
		name: 'Tiếng Việt',
		country: 'vn',
		flagPath: 'vn.svg',
		alternateName: 'Vietnamese',
		languageCode: 'vi',
	},
	{
		locale: 'tr',
		name: 'Türkçe',
		country: 'tr',
		flagPath: 'tr.svg',
		alternateName: 'Turkish',
		languageCode: 'tr',
	},
	{
		locale: 'tk',
		name: 'Türkmençe',
		country: 'tm',
		flagPath: 'tm.svg',
		alternateName: 'Turkmen',
		languageCode: 'tk',
	},
	{
		locale: 'uk',
		name: 'Українська',
		country: 'ua',
		flagPath: 'ua.svg',
		alternateName: 'Ukrainian',
		languageCode: 'uk',
	},
	{
		locale: 'ur',
		name: 'اردو',
		country: 'pk',
		flagPath: 'pk.svg',
		alternateName: 'Urdu',
		languageCode: 'ur',
	},
	{
		locale: 'kk',
		name: 'Kazakh',
		country: 'kz',
		flagPath: 'kz.svg',
		alternateName: 'Kazakh',
		languageCode: 'kk',
	},
	{
		locale: 'ky',
		name: 'Kyrgyz',
		country: 'kg',
		flagPath: 'kg.svg',
		alternateName: 'Kyrgyz',
		languageCode: 'ky',
	},
	{
		locale: 'dv',
		name: 'Divehi',
		country: 'mv',
		flagPath: 'mv.svg',
		alternateName: 'Dhivehi',
		languageCode: 'dv',
	},
] as const;

/**
 * @strict
 */
export interface SiteLanguage {
  elements: SiteElements;
  blocks: SiteBlocks;
  pages: SitePages;
  nav: SiteNavItem[];
  blogReadingTimeText?: string;
  src?: Svg;
  languageCode?: string;
  metaTitle?: string;
  name?: NotEmptyString;
  isHidden?: boolean;
  alt?: SiteLanguageFlagAlt;
  orderPosition?: number;
  flagPath?: Svg;
  cookieBannerAcceptText?: NotEmptyString;
  cookieBannerDeclineText?: NotEmptyString;
  cookieBannerDisclaimer?: string;
  metaHtmlLanguage: string;
  locale?: SiteLanguageLocale;
  country?: string | null;
  isNavHidden: boolean;
  homePageId: NotEmptyString;
  alternateName?: string;
  ajvValidationProps?: any;
}

/**
 * @strict
 */
export interface SiteMeta {
  googleAdsIds?: GoogleAdsIds;
  shouldAddWWWPrefixToDomain: boolean;
  version: number;
  template: string;
  ecommerceStoreId?: EcommerceStoreId;
  demoEcommerceStoreId: DemoEcommerceStoreId;
  customMeta?: string;
  customBodyElements?: string;
  customHeadElements?: string;
  typographyStylesId?: string;
  buttonSetId?: '0' | '1' | '2';
  facebookPixel?: FacebookPixelId | '';
  googleAdSense?: string;
  googleAdMob?: string;
  googleTagManager?: GoogleTagManagerId | '';
  googleAnalytics?: GoogleAnalyticsId | '';
  hotjar?: HotjarId | '';
  metaTitle?: string;
  ogImagePath?: string;
  ogImageOrigin?: AssetsOrigin;
  ogImageAlt?: string;
  faviconPath?: string;
  faviconOrigin?: AssetsOrigin;
  stripePublicApiKey?: StripePublicApiKey | '';
  whatsAppNumber?: WhatsAppNumber | '';
  whatsAppMessage?: string;
  isCookieBarEnabled?: boolean;
  ecommerceType?: 'zyro';
  defaultLocale: string;
  isPrivateModeActive: boolean;
  aiWebsiteType?: AiWebsiteType;
  isCustomCodeDisabled?: boolean;
  payseraVerificationCode?: string;
  isExistingStoreWithDynamicTemplate?: boolean;
  isLayout?: boolean;
  isExpressCheckoutEnabled?: boolean;

  // properties bellow likely should be deprecated
  GAId?: string;
  colorSetId?: string;
  migrationToLayoutModifications?: object;
}

/**
 * @strict
 */
interface SiteTypographyFonts {
  primary: SiteTypographyFont;
  secondary: SiteTypographyFont;
}

/**
 * @strict
 */
export interface SiteStyles {
  h1: SiteTypographyStyles;
  h2: SiteTypographyStyles;
  h3: SiteTypographyStyles;
  h4: SiteTypographyStyles;
  h5: SiteTypographyStyles;
  h6: SiteTypographyStyles;
  'body-small': SiteTypographyStyles;
  body: SiteTypographyStyles;
  'body-large': SiteTypographyStyles;
  'nav-link': SiteNavLinkTypographyStyles;
  font: SiteTypographyFonts;
  'grid-button-primary': SiteGridButtonStyle;
  'grid-button-secondary': SiteGridButtonStyle;
}

/**
 * @strict
 */
interface SiteForm {
  token?: FormToken;
}

export type SiteForms = Record<string, SiteForm>;

// ts-to-zod doesn't support union record keys nor mapped types
export type SiteLanguages = Record<string, SiteLanguage>;

export type SiteBlogCategory = { name: string; };
export type SiteBlogCategories = Record<string, SiteBlogCategory>;

export interface SiteFont {
  family: string;
  type: string;
  weights?: (string | number)[];
}

export type SiteFonts = SiteFont[];

export interface SiteBuilderCompletedSteps {
  hasChangedLogo?: boolean;
  textBoxChangeCount?: number;
  homePageTextBoxChangeCount?: number;
  hasCompletedPageSeo?: boolean;
  isDomainPropagatedEventSent?: boolean;
  [key: string]: unknown;
}

export interface SiteData {
  meta: SiteMeta;
  domain?: string;
  freeDomain?: string;
  languages: SiteLanguages;
  styles: SiteStyles;
  forms: SiteForms;
  fonts: SiteFonts;
  siteId?: string;
  builderCompletedSteps?: SiteBuilderCompletedSteps;
  blogCategories: SiteBlogCategories;
  ecommerceShoppingCart?: SiteEcommerceShoppingCart;
  ecommerceSeoChanges?: SiteEcommerceSeoChanges[];

  // bellow properties possibly should be deprecated
  navigationBlock?: Record<string, unknown>;
  user?: Record<string, unknown>;
}

export interface SiteLanguageSwitcherLanguage {
  country: string;
  flagPath: string;
  href: string;
  isHidden: boolean;
  locale: string;
  name: string;
}

export type UpsertRegionCountry = {
  country_code: string
  provinces: string[]
}

export interface SiteEcommerceProductTemplate extends SiteEcommerceDynamicProductPage {
	id: string
}
